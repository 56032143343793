import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './index.css';
import App from './App';
import DeviceProvider from './components/DeviceProvider';
import AboutUs, { aboutUsPath } from './pages/AboutUs';
import Events, { eventsPath } from './pages/AboutUs/Events';
import Greetings, { greetingsPath } from './pages/AboutUs/Greetings';
import Join, { joinPath } from './pages/AboutUs/Join';
import Practice, { practicePath } from './pages/AboutUs/Practice';
import Access, { accessPath } from './pages/Access';
import Community, { communityPath } from './pages/Community';
import Contact, { contactPath } from './pages/Contact';
import Error from './pages/Error';
import Home, { homePath } from './pages/Home';
import Links, { linksPath } from './pages/Links';
import Parent from './pages/Parent';
import Policy, { policyPath } from './pages/Policy';
import Schedule, { schedulePath } from './pages/Schedule';
import Concert, { concertPath } from './pages/Schedule/Concert';
import PracticeSchedule, {
  practiceSchedulePath,
} from './pages/Schedule/PracticeSchedule';
import Sitemap, { sitemapPath } from './pages/Sitemap';
import UrizenData, { urizenDataPath } from './pages/UrizenData';
import Framework, { frameworkPath } from './pages/UrizenData/Framework';
import History, { historyPath } from './pages/UrizenData/History';
import Teachers, { teachersPath } from './pages/UrizenData/Teachers';
import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <App errorElement={<Error />} />,
    children: [
      { path: homePath, element: <Home /> },
      {
        path: aboutUsPath,
        element: <Parent />,
        children: [
          { index: true, element: <AboutUs /> },
          { path: greetingsPath, element: <Greetings /> },
          { path: practicePath, element: <Practice /> },
          { path: eventsPath, element: <Events /> },
          { path: joinPath, element: <Join /> },
        ],
      },
      {
        path: urizenDataPath,
        element: <Parent />,
        children: [
          { index: true, element: <UrizenData /> },
          { path: teachersPath, element: <Teachers /> },
          { path: frameworkPath, element: <Framework /> },
          { path: historyPath, element: <History /> },
        ],
      },
      {
        path: schedulePath,
        element: <Parent />,
        children: [
          { index: true, element: <Schedule /> },
          { path: concertPath, element: <Concert /> },
          { path: practiceSchedulePath, element: <PracticeSchedule /> },
        ],
      },
      {
        path: communityPath,
        element: <Parent />,
        children: [{ index: true, element: <Community /> }],
      },
      {
        path: linksPath,
        element: <Parent />,
        children: [{ index: true, element: <Links /> }],
      },
      { path: sitemapPath, element: <Sitemap /> },
      { path: policyPath, element: <Policy /> },
      { path: accessPath, element: <Access /> },
      { path: contactPath, element: <Contact /> },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <DeviceProvider>
      <RouterProvider router={router} />
    </DeviceProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
