import { memo, useEffect } from 'react';

const Practice = (): React.ReactElement => {
  useEffect(() => {
    document.title =
      '練習内容 | コール・ユリゼンとは | 東京大学混声合唱団コール・ユリゼン';
  }, []);
  return (
    <>
      <h1>練習内容</h1>
      <p>
        ユリゼンの練習はどんな風に進んでいくのでしょうか？
        土曜の練習の一例を、時間を追って少し見てみましょう。
      </p>
      <p>
        ちなみに水曜は17時から練習が始まります。
        授業があって最初から参加できないという方も、遅れて参加することができますのでご心配なく。
      </p>
      <h3>PM3:00～ 練習開始（準備運動）</h3>
      <p>お昼が過ぎ、丁度おやつを食べたくなる時間に練習は始まります。</p>
      <p>
        近くにコンビニがあるので、練習前に飲み物を買ったり（歌うとのどが渇きます！）、
        おやつや遅い食事をとったりする人が結構います。
      </p>
      <p>
        練習前に英気を養ったら早速練習開始！と言いたいのですが、まずは準備運動から。
      </p>
      <p>
        最初は皆列になって肩を揉み合います。
        何故かいつも男性は動きながら（全く必要ないのですが）やっているのですが、どうしてなんでしょう（笑）。
      </p>
      <p>
        その後は学生指揮者による準備運動が始まり、ここで体全体をほぐします。
        歌とは喉だけでなく体全体を使うれっきとした運動なので、準備段階とは言えまじめに取り組みます。
      </p>

      <h3>PM3:10～ 発声練習</h3>
      <p>
        準備運動が終わったら次は発声練習。まずは呼吸の練習から始めます。
        この呼吸がきれいな声、力強い声に関わらず発声の基本になります。
      </p>
      <p>
        それが終わると次は発声。
        ここで喉を暖めるとともに、音のとり方も確認します。
      </p>
      <p>
        頼れる先輩が近くにいて、分からないことがあってもすぐに聞けたり、
        不具合があればアドバイスをもらえたりするので初心者でも安心です。
      </p>

      <h3>PM3:25～ 休憩兼移動</h3>
      <p>
        発声が終わると、移動を兼ねた５～１０分の休憩タイム。
        パートごとに練習するために、各パートがそれぞれの練習場所まで移動します。
      </p>

      <h3>PM3:35～ パート練習</h3>
      <p>
        以上のことが終わったら、本日の課題曲に取り組みます。
        まずはパートごとに分かれて練習を開始。
      </p>
      <p>パート練習ではパートリーダーが中心となって練習を行います。</p>
      <p>
        課題曲を幾つかに区切って部分ごとに練習し、音ズレの問題等を直していき全体練習に備えます。
        まだここは１パートだけなのでやりやすい分、
        ここでしっかりと合わせておかないと全体練習がおぼつかなくなるので皆真剣に練習します。
      </p>
      <p>
        パート練習が終わったら、再び休憩兼移動を挟んで、次はいよいよ全体練習です！
      </p>

      <h3>PM4:40～ 全体練習</h3>
      <p>
        ここからは学生指揮者による全体練習、４パート合わせた課題曲の仕上げの練習が始まります。
      </p>
      <p>
        １パートだけとは違い４パート合同での合唱は、
        各パートの声が響きあって美しいハーモニーが生まれる・・・のですが、
        その分パート練習では気づかなかった問題もたくさん出てきます。
      </p>
      <p>
        その問題を修正し、より美しい合唱を作り出していくのが全体練習の役割です。
      </p>

      <h3>PM5:10～ 休憩</h3>
      <p>
        全体練習が一段落したら５～１０分の休憩タイム。
        先程までの真剣さから打って変わってなごやかムードに。
        他の団員と話したり、窓辺で寛いだり、のど飴や飲み物で調子を整えたり、
        それぞれが自由に過ごし、残りの全体練習に備えます。
      </p>

      <h3>PM6:00 練習終了</h3>
      <p>
        休憩後の残りの全体練習が終わったら団長が司会のミーティングを開始。
        色々な連絡事項や次回の練習の予定をお知らせしています。
        それが終わったら解散、ではなくみんなで話しながら渋谷に歩いていくのがお決まり。
        練習が終わってもユリゼンという団として行動する、
        これがユリゼンの仲の良さを生み出す要因の一つなのかもしれません。
      </p>
      <p>
        渋谷のマークシティ下に着いたら、アフターの時間になるまで暫く雑談タイム。
        思い思いの話で盛り上がります。 そしてその後はアフター開始！
      </p>
    </>
  );
};

export default memo(Practice);
export const practicePath = 'practice';
