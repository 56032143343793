import { memo, useEffect } from 'react';

const Events = (): React.ReactElement => {
  useEffect(() => {
    document.title =
      'イベント | コール・ユリゼンとは | 東京大学混声合唱団コール・ユリゼン';
  }, []);
  return (
    <>
      <h1>イベント</h1>
      <p>
        ユリゼンでは、年間を通じてたくさんのイベントがあります。
        簡単に1年間のおおよその流れをご紹介しましょう！
      </p>
      <h2>新歓イベント</h2>
      <h3>新歓合唱祭（4月11日(土)）</h3>
      <p>
        東大の合唱系サークルが集まり、新入生に向けて演奏します。
        合唱祭終了後には公開練習と新歓コンパがあり、ユリゼンの雰囲気を実際に体験することができます。
      </p>
      <p>
        公開練習で初めてユリゼンに来る新入生も多いですが、その前から練習に参加している新入生もたくさんいます。
        もちろん、この日を逃してしまってもいつでも練習見学・参加OKです！
      </p>

      <h3>新歓バーベキュー（4月26日(日)）</h3>
      <p>
        広々とした自然豊かな公園で、ユリゼンのみんなでバーベキューをします。
        楽しく話しながら美味しいご飯を食べたあとは、公園でバレーボールをして遊んだりもします。
        新入生は無料なので、ぜひ来て一緒にお話しましょう！
      </p>

      <h3>新歓ボウリング（5月10日(日)）</h3>
      <p>
        ユリゼンにも少し慣れてきたかなという時期に、もっとユリゼンに親しんでもらおうということで、
        毎年新歓ボウリングという企画を行っています。
        トップ賞とブービー賞には景品も用意しています！
      </p>

      <h3>五月祭（5月17日(日)）</h3>
      <p>
        五月祭は、東大の本郷キャンパスで行われる学園祭なのですが、とても重要なイベントの1つです。
        というのも、この五月祭におけるステージが新入生にとって最初のステージになるからです。
        五月祭では、とにかく元気に楽しく歌うことが一番です。
      </p>

      <h3>新歓キャンプ（5月下旬）</h3>
      <p>
        五月祭も終わって一息ついたころに、みんなで1泊2日のキャンプに行きます。
        この新歓キャンプでは、合宿と違って練習がありません！
        河原で水遊びをしたり、バーベキューを楽しんだり、
        キャンプファイヤーを囲んで歌ったり、みんなで花火をしたり......。
        とにかく楽しめる二日間です(*・&forall;・*)
      </p>

      <h3>新歓アンサンブル（6月下旬）</h3>
      <p>
        ユリゼンでは、アンサンブルといって少人数で歌う内部演奏会が何度かあります。
        新歓アンサンブルは、新入生を迎えて行う初めてのアンサンブルです。
        それぞれ組アンサンブルと自主アンサンブルとがあり、
        組アンサンブルは団員全体がいくつかの組に分かれ、お題に沿って曲を演奏します。
        自主アンサンブルでは、団員同士が思い思いに集い、自分たちの好きな曲を歌います。
        少人数だと責任も増して緊張しますが、得られるものも大きいです。
      </p>

      <h2>夏合宿（8月中旬）</h2>
      <p>
        夏合宿は定期演奏会に向けての1週間の強化合宿です。
        午前も午後も練習があり、常任指揮の先生による指導やボイストレーニングもあるので、
        集中して練習することができます。
        練習だけでなく、団員のみんなと生活を共にするので、充実した1週間を送ることができます。
      </p>

      <h2>駒場祭（11月下旬）</h2>
      <p>
        駒場祭は東大の駒場キャンパスで行われる学園祭ですが、五月祭同様に合唱ステージがあります。
        しかし、もっと大切なことが1年生にはあります。それは模擬店の出店です。
        1年生同士で協力し合い、企画から運営まで全て1年生が行うため、
        毎年この駒場祭を境にそれまで以上に1年生同士の絆が深まります。
      </p>

      <h2>定期演奏会（12月）</h2>
      <p>
        ユリゼンでの活動は、全てこの定期演奏会のためにあると言っていいでしょう。
        一年間練習してきた成果を大勢のお客さんの前で披露します。
      </p>
      <p>
        学生指揮者によるステージや、振り付けや照明などの演出を自分たちだけで加えたステージなど、
        様々なステージがあります。
        演奏だけでなく、パンフレット作成や当日の会場進行など、演奏会に関わるすべてを団員だけで作り上げるため、
        他の演奏会とは違った感動や達成感が得られます。
      </p>

      <h2>春合宿（3月下旬）</h2>
      <p>
        春合宿は新歓直前に行われる合宿です。
        夏合宿同様に午前午後と練習をして、新歓合唱祭に向けての準備を整えます。
        アンサンブルも行います。
      </p>

      <h2>その他の企画・イベント</h2>
      <p>
        このほかにも、
        自由参加ですがユリゼンのみんなとディズニーランド（ディズニーシー）に行くというディズニー企画や、
        伊豆大島に1泊3日の旅行に行く島企画、1泊2日のスキー企画、スイーツ食べ放題に行くスイパラ企画、
        みんなでわいわいボードゲーム企画など、様々な企画が盛りだくさんです！
      </p>
    </>
  );
};

export default memo(Events);
export const eventsPath = 'events';
