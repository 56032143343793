import { CSSProperties, memo } from 'react';
import { Link } from 'react-router-dom';

import { useDevice } from '../hooks/useDevice';
import headerImage from '../images/header_bg.jpg';
import logo from '../images/header_logo.gif';
import logoMobile from '../images/header_logo_mobile.gif';
import { homeUrl } from '../pages/Home';

const headerStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  backgroundColor: '#d7762f',
  backgroundImage: `url(${headerImage})`,
  backgroundSize: '100% 100%',
};
const logoStyle: CSSProperties = {
  paddingTop: '20px',
  paddingLeft: '38px',
};
const logoMobileStyle: CSSProperties = {
  padding: '10px',
};

const Header = (): React.ReactElement => {
  const { isMobile } = useDevice();
  return (
    <div style={headerStyle}>
      <Link to={homeUrl}>
        {isMobile ? (
          <img src={logoMobile} style={logoMobileStyle} />
        ) : (
          <img src={logo} style={logoStyle} />
        )}
      </Link>
    </div>
  );
};

export default memo(Header);
