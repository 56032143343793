import { memo, ReactNode } from 'react';
import { Link } from 'react-router-dom';

export type Props = {
  if: boolean;
  to: string;
  children?: ReactNode;
};

const LinkIf = ({ if: condition, to, children }: Props): React.ReactElement => {
  return condition ? <Link to={to}>{children}</Link> : <>{children}</>;
};

export default memo(LinkIf);
