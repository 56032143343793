import { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';

import ExternalLink from '../../components/ExternalLink';
import { contactUrl } from '../Contact';
import { izanaiUrl } from '../Home';
import { practiceScheduleUrl } from '../Schedule';

const Join = (): React.ReactElement => {
  useEffect(() => {
    document.title =
      '入団するには | コール・ユリゼンとは | 東京大学混声合唱団コール・ユリゼン';
  }, []);
  return (
    <>
      <h1>入団するには</h1>
      <h2>新歓情報</h2>
      <h3>体験練習のお知らせ</h3>
      <p>新歓期は、練習30分前から団員がプラカードを持ってお待ちしています。</p>
      <p>
        駒場で練習する時は東大の正門前かコミュニケーションプラザ前（生協食堂前）に、
        幼稚園で練習する時は京王井の頭線渋谷駅中央改札前にお越しください。
      </p>
      <p>
        練習日程は<Link to={practiceScheduleUrl}>こちら</Link>からどうぞ。
      </p>

      <h3>体験練習のメリット</h3>
      <p>
        <strong>1. ユリゼンの楽しさを肌で味わうことができる</strong>
      </p>
      <p>
        ここや<ExternalLink to={izanaiUrl}>いざない</ExternalLink>
        で紹介されているユリゼンの魅力、これを肌で感じることができます。
        特に皆で歌う楽しさはやってみて初めて分かることだと思います。
      </p>
      <p>
        <strong>2. アフターが無料！</strong>
      </p>
      <p>
        練習後には毎回、アフターと呼ばれる食事会があります。
        土曜のアフターはお酒がありますが基本的にまったりと飲んでいます。
        飲めない方に強要するようなことはありませんのでご安心ください。
        アフターは自由参加ですが、新歓期の間、新入生は無料です。
        ぜひ来てくださいね！
      </p>

      <h3>途中から参加する方へ</h3>
      <p>
        練習時間の途中から参加したいけど場所が分からない、という方は、
        いざないに掲載されている連絡先か、こちらの
        <Link to={contactUrl}>お問い合わせ</Link>
        からメールを送っていただければ、団員が迎えに行きます。
        少しでもユリゼンに興味があれば、遠慮せずにぜひ連絡してください！
      </p>

      <h2>入団したい方へ</h2>
      <p>
        コール・ユリゼンでは、<b>新歓期に限らずいつでも</b>、
        男性は東大生、女性は東大を含む大学（共学、女子大、音大など、大学の形式は問いません）から
        幅広く団員を募集しています。
        実際に10月ごろや2年生になってから入った団員もいますのでご安心ください。
      </p>
      <p>
        ちなみに合唱の経験はなくても大丈夫！
        実際ユリゼンの団員は半数以上が合唱未経験者で、
        上級生やボイストレーニングの先生からの指導で着実にうまくなっていくので、
        未経験者の方も敷居の高さを感じることは決してありません！
      </p>
      <p>
        しかし、文章だけでは伝わらないこともたくさんありますし、
        雰囲気は実際に体験してみないと分からないと思います。
        ですからまずは体験練習に参加してみてください♪
      </p>
      <p>
        見学希望は<Link to={contactUrl}>お問い合わせ</Link>からどうぞ。
      </p>
    </>
  );
};

export default memo(Join);
export const joinPath = 'join';
