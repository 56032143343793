export const frameworkData: Array<{
  title: string;
  roles: Array<{ role: string; description: string }>;
}> = [
  {
    title: '執行部 （3年生）',
    roles: [
      {
        role: '団長',
        description:
          'ユリゼンの代表者です。全ての仕事の大まかな流れを把握しています。',
      },
      {
        role: '庶務',
        description:
          '普段の練習から合宿の時などのユリゼンを運営する際の全般的な事務を担っています。',
      },
      {
        role: '渉外',
        description:
          '訪問演奏や他団との交流企画などを通じて、ユリゼンと外部をつなぐ働きを担っています。',
      },
      {
        role: '会計',
        description:
          'ユリゼンの団費や合宿費用などを管理し、ユリゼンの財政面を担っています。',
      },
      {
        role: '会計監査',
        description:
          '会計が権限を悪用していないか、常に確認する権利を持っています。',
      },
      {
        role: '学生指揮者',
        description:
          '普段の練習のときに指揮を振りつつユリゼンの技術面を見ています。',
      },
      {
        role: '学生ピアニスト',
        description: '普段の練習のときに指揮者と連携してピアノの伴奏をします。',
      },
      {
        role: 'パートリーダー',
        description: 'パート練習などを通じて、各パートを技術面で支えます。',
      },
    ],
  },
  {
    title: '常任係 （２年生）',
    roles: [
      { role: '常任会計', description: 'ユリゼンの財務管理を担当します。' },
      { role: '春合宿責任者', description: '春合宿の運営を担当します。' },
      { role: '夏合宿責任者', description: '夏合宿の運営を担当します。' },
      { role: '合宿会計', description: '合宿にかかる費用の管理をします。' },
      {
        role: '合唱団連絡委員',
        description:
          '新歓活動や合唱祭などで必要となる他の合唱団との連携を受け持ちます。',
      },
      {
        role: 'コミプラ係',
        description:
          '水曜の練習場所であるコミュニケーションプラザの利用者会議に出席して練習場所を確保しています。',
      },
      {
        role: '幼稚園係',
        description: '土曜の練習場所である鶯谷さくら幼稚園との仲介役です。',
      },
      {
        role: '外報',
        description:
          '新入生にユリゼンを楽しんでもらうためにどう動けばいいか考え、実践する係です。',
      },
      {
        role: '会報',
        description:
          '団内向けの小冊子を作成し、アンサンブルアンケートの集計結果などを全体に知らせます。',
      },
      {
        role: '集曲',
        description:
          '合宿のときなどのアンサンブルの司会や運営をしたり、練習に使う冊子を制作したりします。',
      },
      {
        role: '技系補佐',
        description:
          '自主練用にMIDI（音楽ファイル）を作製したり、練習を技術面からより良くしたりします。',
      },
      {
        role: '荷物運びチーフ',
        description:
          '練習に使うポータブル電子キーボードなどの運搬を指揮します。',
      },
      {
        role: '部屋取りチーフ',
        description:
          '土曜の練習で学生会館などを使用する場合に部屋の利用予約を指揮します。',
      },
    ],
  },
];
