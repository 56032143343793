import { CSSProperties, memo } from 'react';

import ExternalLinkIf from './ExternalLinkIf';
import { useDevice } from '../hooks/useDevice';

const containerStyle: CSSProperties = {
  textAlign: 'center',
};

export type Props = {
  src?: string | undefined;
  alt?: string | undefined;
  size?: 'small' | 'medium' | 'large';
  externalTo?: string | undefined;
};

const Img = ({
  src,
  alt,
  size = 'medium',
  externalTo,
}: Props): React.ReactElement => {
  const { isMobile } = useDevice();
  const widthMobile = {
    large: '100%',
    medium: '100%',
    small: '60%',
  };
  const widthPc = {
    large: '90%',
    medium: '60%',
    small: '40%',
  };
  const imgStyle: CSSProperties = {
    width: isMobile ? widthMobile[size] : widthPc[size],
    paddingBottom: '15px',
  };
  return (
    <div style={containerStyle}>
      <ExternalLinkIf if={externalTo !== undefined} to={externalTo ?? ''}>
        <img src={src} alt={alt} style={imgStyle} />
      </ExternalLinkIf>
    </div>
  );
};

export default memo(Img);
