import { CSSProperties, memo, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import contactImage from '../images/menu_contact.jpg';
import infoImage from '../images/menu_info.jpg';
import { accessUrl } from '../pages/Access';
import { contactUrl } from '../pages/Contact';
import { policyUrl } from '../pages/Policy';
import { sitemapUrl } from '../pages/Sitemap';

const NonMobileMenu = (): React.ReactElement => {
  const [infoOpen, setInfoOpen] = useState<boolean>(false);
  const [contactOpen, setContactOpen] = useState<boolean>(false);
  const infoStyle: CSSProperties = {
    position: 'absolute',
    right: '140px',
    top: `-${(15 + 40 + 4).toString()}px`,
    width: '130px',
    height: infoOpen ? '104px' : '44px',
    overflowY: 'hidden',
    transition: '.3s',
  };
  const contactStyle: CSSProperties = {
    ...infoStyle,
    right: '0px',
    height: contactOpen ? '104px' : '44px',
  };
  const infoImageStyle: CSSProperties = {
    width: '80px',
    height: '40px',
    backgroundImage: `url(${infoImage})`,
    backgroundRepeat: 'no-repeat',
    borderStyle: 'solid',
    borderWidth: '4px',
    borderColor: 'var(--orange)',
    borderBottomStyle: 'none',
    marginRight: '5px',
    marginLeft: 'auto',
  };
  const contactImageStyle: CSSProperties = {
    ...infoImageStyle,
    backgroundImage: `url(${contactImage})`,
    marginRight: 'auto',
    marginLeft: '5px',
  };
  const linkStyle: CSSProperties = {
    display: 'block',
    width: '120px',
    backgroundColor: 'var(--orange)',
    color: 'var(--white)',
    fontWeight: 'bold',
    fontFamily: 'var(--bold-font-family)',
    marginTop: '2px',
    lineHeight: '28px',
    paddingLeft: '5px',
    paddingRight: '5px',
  };
  const onMouseEnterInfo = () => setInfoOpen(true);
  const onMouseLeaveInfo = () => setInfoOpen(false);
  const onMouseEnterContact = () => setContactOpen(true);
  const onMouseLeaveContact = () => setContactOpen(false);
  const location = useLocation();
  useEffect(() => {
    setInfoOpen(false);
    setContactOpen(false);
  }, [location]);
  return (
    <>
      <div
        style={infoStyle}
        onMouseEnter={onMouseEnterInfo}
        onMouseLeave={onMouseLeaveInfo}
      >
        <div style={infoImageStyle} />
        <Link to={sitemapUrl} style={linkStyle}>
          サイトマップ
        </Link>
        <Link to={policyUrl} style={linkStyle}>
          本サイトについて
        </Link>
      </div>
      <div
        style={contactStyle}
        onMouseEnter={onMouseEnterContact}
        onMouseLeave={onMouseLeaveContact}
      >
        <div style={contactImageStyle} />
        <Link to={accessUrl} style={linkStyle}>
          活動場所
        </Link>
        <Link to={contactUrl} style={linkStyle}>
          お問い合わせ
        </Link>
      </div>
    </>
  );
};

export default memo(NonMobileMenu);
