import { memo, useEffect } from 'react';

const Teachers = (): React.ReactElement => {
  useEffect(() => {
    document.title =
      '先生の紹介 | ユリゼンデータ | 東京大学混声合唱団コール・ユリゼン';
  }, []);
  return (
    <>
      <h1>先生の紹介</h1>
      <p>
        ユリゼンには私たちの歌を支えてくれるプロの先生方がいらっしゃいます。
        指揮者、ピアニスト、ボイストレーナーの総勢6名の先生方があなたの技術向上の力強い味方になってくれます。
        だから初心者でも大丈夫です！
      </p>
      <p>
        「プロの先生かぁ、なんだか怖いなぁ」とお思いかもしれませんが、まったくそんなことはありません。
        皆さん冗談を交えて楽しくわかりやすくご指導くださる、超がつくほどの気さくで親しみやすい方々です。
      </p>
      <p>&nbsp;</p>
      <p>詳細はただいま調整中です。もうしばらくお待ちください。</p>
    </>
  );
};

export default memo(Teachers);
export const teachersPath = 'teachers';
