import { memo, useEffect } from 'react';

import ExternalLink from '../../components/ExternalLink';
import Img from '../../components/Img';
import flyer61 from '../../images/flyer_61.jpg';
import regularConcert from '../../images/regular_concert_59.jpg';

const Concert = (): React.ReactElement => {
  useEffect(() => {
    document.title =
      '演奏会 | ユリゼンデータ | 東京大学混声合唱団コール・ユリゼン';
  }, []);
  return (
    <>
      <h1>演奏会</h1>
      <h2>演奏会のご案内</h2>
      <p>今後の演奏会の予定はありません。</p>
      <Img src={regularConcert} alt="定期演奏会写真" size="large" />

      <h2>過去の演奏会</h2>
      <Img src={flyer61} alt="第61回定演チラシ" size="medium" />

      <h3>第61回定期演奏会</h3>
      <table className="unset">
        <tbody>
          <tr>
            <td width="40">日時</td>
            <td>
              <strong>2021年12月25日(土) 17時開演</strong>
            </td>
          </tr>
          <tr>
            <td>場所</td>
            <td>
              <strong>三鷹市芸術文化センター 風のホール</strong> (
              <ExternalLink to="https://mitaka-sportsandculture.or.jp/geibun/access/">
                アクセス
              </ExternalLink>
              )
            </td>
          </tr>
          <tr>
            <td colSpan={2}>入場無料、全席自由</td>
          </tr>
        </tbody>
      </table>
      <h4>ステージ構成・曲目</h4>
      <table className="unset">
        <tbody>
          <tr>
            <td width="20">Ⅰ</td>
            <td>学生指揮者ステージ『くちびるに歌を』(信長貴富) より</td>
          </tr>
          <tr>
            <td>Ⅱ</td>
            <td>演出ステージ「東大ショークワイア コラボ」</td>
          </tr>
          <tr>
            <td>Ⅲ</td>
            <td>OBOG合同ステージ『筑後川』(團伊玖磨)</td>
          </tr>
        </tbody>
      </table>
      <p>
        <strong>OBOGの皆様</strong>: OBOG合同ステージ特設サイトは
        <ExternalLink to="http://chorohbierren.com/ChorUrizenLastConcert/concert.html">
          こちら
        </ExternalLink>
        。たくさんの方のご参加ありがとうございました。
      </p>

      <h3>第60回記念定期演奏会</h3>
      <table className="unset">
        <tbody>
          <tr>
            <td width="40">日時</td>
            <td>
              <strong>2020年12月26日(土) 16時開演</strong>
            </td>
          </tr>
          <tr>
            <td>場所</td>
            <td>
              <strong>小金井宮地楽器ホール 大ホール</strong>
            </td>
          </tr>
        </tbody>
      </table>
      <h4>ステージ構成・曲目</h4>
      <table className="unset">
        <tbody>
          <tr>
            <td width="20">Ⅰ</td>
            <td>
              常任指揮者ステージ『Messe Brève No. 7 in C』(Charles Gounod)
            </td>
          </tr>
          <tr>
            <td>Ⅱ</td>
            <td>学生指揮者ステージ『三つの不思議な仕事』(池辺晋一郎)</td>
          </tr>
          <tr>
            <td>Ⅲ</td>
            <td>演出ステージ『ディズニー曲集』</td>
          </tr>
          <tr>
            <td>Ⅳ</td>
            <td>OBOG合同ステージ『旅』(佐藤眞)</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default memo(Concert);
export const concertPath = 'concert';
