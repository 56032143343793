import { memo, useEffect } from 'react';

import ExternalLink from '../components/ExternalLink';

export const visitFormUrl =
  'https://docs.google.com/forms/d/e/1FAIpQLSc3l47xs80fNlXvKruPHtqzqYPMhedzPvnF1ZS6iML5PqELgw/viewform';
export const contactFormUrl =
  'https://docs.google.com/forms/d/e/1FAIpQLSfvChcsok8UmoOQ7aJIG8WXIpMnPhh3SSOqOTM1TFqletw_nA/viewform';

const Contact = (): React.ReactElement => {
  useEffect(() => {
    document.title = 'お問い合わせ | 東京大学混声合唱団コール・ユリゼン';
  }, []);
  return (
    <>
      <h1>お問い合わせ</h1>

      {/* <h3>
        <ExternalLink to={visitFormUrl}>見学希望</ExternalLink>
      </h3>
      <p>
        見学希望専用のメールフォームです。 見学希望の方は
        <ExternalLink to={visitFormUrl}>こちら</ExternalLink>から。
      </p> */}

      <h3>
        <ExternalLink to={contactFormUrl}>
          {/* その他 */}お問い合わせ
        </ExternalLink>
      </h3>
      <p>
        {/* 見学希望以外の内容の */}お問い合わせは
        <ExternalLink to={contactFormUrl}>こちら</ExternalLink>から。
      </p>

      <h3>郵便物</h3>
      <p>
        恐れ入りますが、現在郵便物はお受けしておりません。郵送をご希望の場合、
        <ExternalLink to={contactFormUrl}>お問い合わせフォーム</ExternalLink>
        からその旨をご連絡ください。
      </p>
      {/* <p>郵送をしたい場合は</p>
      <blockquote>
        <p>
          〒153-8902
          <br />
          東京都目黒区駒場3-8-1
          <br />
          東京大学教養学部内キャンパスプラザＢ302
          <br />
          東京大学混声合唱団コール・ユリゼン
        </p>
      </blockquote>
      <p>までお送りください。よろしくお願いします。</p> */}
    </>
  );
};

export default memo(Contact);
export const contactPath = 'contact';
export const contactUrl = `/${contactPath}`;
