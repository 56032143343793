import { CSSProperties, memo, ReactNode, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import MobileMenubar from './components/MobileMenubar';
import NonMobileMenu from './components/NonMobileMenu';
import Sidebar from './components/Sidebar';
import { useDevice } from './hooks/useDevice';
import mainBg from './images/main_bg.gif';

const appStyle: CSSProperties = {
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
};
const headerStyle: CSSProperties = {
  width: '100%',
  height: '150px',
  borderBottomStyle: 'solid',
  borderBottomWidth: '4px',
  borderBottomColor: 'var(--orange)',
};
const headerMobileStyle: CSSProperties = {
  ...headerStyle,
  height: '75px',
};
const contentStyle: CSSProperties = {
  width: '100%',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'row',
};
const sidebarStyle: CSSProperties = {
  backgroundColor: 'var(--light-orange)',
  width: '230px',
  flexShrink: 0,
  borderRightStyle: 'solid',
  borderRightWidth: '4px',
  borderRightColor: 'var(--orange)',
};
const mainContentContainerStyle: CSSProperties = {
  flexGrow: 1,
  backgroundImage: `url(${mainBg})`,
  padding: '15px 25px',
};
const mainContentStyle: CSSProperties = {
  maxWidth: '800px',
  margin: 'auto',
  position: 'relative',
};
const mainContentTopStyle: CSSProperties = {
  width: '100%',
  height: '1px',
};
const pcSpacerStyle: CSSProperties = {
  width: sidebarStyle.width,
  flexShrink: 0,
  backgroundImage: mainContentContainerStyle.backgroundImage,
};
const footerStyle: CSSProperties = {
  width: '100%',
};

export type Props = {
  errorElement?: ReactNode;
};

const App = ({ errorElement }: Props): React.ReactElement => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const { isMobile, isPc } = useDevice();
  return (
    <div style={appStyle}>
      {isMobile && (
        <MobileMenubar>
          <div style={sidebarStyle}>
            <Sidebar />
          </div>
        </MobileMenubar>
      )}
      <div style={isMobile ? headerMobileStyle : headerStyle}>
        <Header />
      </div>
      <div style={contentStyle}>
        {!isMobile && (
          <div style={sidebarStyle}>
            <Sidebar />
          </div>
        )}
        <div style={mainContentContainerStyle}>
          <div style={mainContentStyle}>
            <div style={mainContentTopStyle} />
            <Outlet />
            {errorElement}
            {!isMobile && <NonMobileMenu />}
          </div>
        </div>
        {isPc && <div style={pcSpacerStyle} />}
      </div>
      <div style={footerStyle}>
        <Footer />
      </div>
    </div>
  );
};

export default memo(App);
