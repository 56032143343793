import { createContext, ReactNode } from 'react';
import { useMedia } from 'use-media';

export type Device = 'pc' | 'tablet' | 'mobile';
export type DeviceInfo = {
  device: Device;
  isPc: boolean;
  isTablet: boolean;
  isMobile: boolean;
};

export type Props = {
  children?: ReactNode;
};

export const DeviceContext = createContext<DeviceInfo>({
  device: 'pc',
  isPc: true,
  isTablet: false,
  isMobile: false,
});

const DeviceProvider = ({ children }: Props): React.ReactElement => {
  const isPc = useMedia({ minWidth: '960px' });
  const isMobile = useMedia({ maxWidth: '519px' });
  const isTablet = !isPc && !isMobile;
  const device: Device = isPc ? 'pc' : isTablet ? 'tablet' : 'mobile';
  const deviceInfo: DeviceInfo = { device, isPc, isTablet, isMobile };
  return (
    <DeviceContext.Provider value={deviceInfo}>
      {children}
    </DeviceContext.Provider>
  );
};

export default DeviceProvider;

// pc: 960px-
// tablet: 520px-959px
// mobile: (320px)-519px
