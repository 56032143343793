import { memo, useEffect } from 'react';

import ExternalLink from '../components/ExternalLink';

const komabaAccessUrl =
  'https://www.u-tokyo.ac.jp/ja/about/campus-guide/map02_02.html';
const comPlaNorthUrl = 'https://www.u-tokyo.ac.jp/campusmap/cam02_01_51_j.html';
const studentBuildingUrl =
  'https://www.u-tokyo.ac.jp/campusmap/cam02_01_21_j.html';
const campusPlazaBUrl =
  'https://www.u-tokyo.ac.jp/campusmap/cam02_01_43_j.html';
const uguisudaniSakuraUrl = 'https://goo.gl/maps/iZsWj326nXLQkTE16';

const Access = (): React.ReactElement => {
  useEffect(() => {
    document.title = '活動場所 | 東京大学混声合唱団コール・ユリゼン';
  }, []);
  return (
    <>
      <h1>活動場所</h1>
      <h2>
        <ExternalLink to={komabaAccessUrl}>東大駒場キャンパス</ExternalLink>
      </h2>
      <p>
        <strong>アクセス方法</strong>
      </p>
      <p>
        京王井の頭線駒場東大前駅（渋谷から2駅）から歩いてすぐで正門に着き、
        そこから約5分で各練習場所（主に2箇所）に着くことができます。
      </p>

      <h3>
        <ExternalLink to={comPlaNorthUrl}>
          コミュニケーションプラザ北館
        </ExternalLink>
      </h3>
      <p>
        <b>水曜日の練習</b>で使っている建物です。
        2階の舞台芸術実習室で練習しています。
      </p>
      <h3>
        <ExternalLink to={studentBuildingUrl}>学生会館</ExternalLink>
      </h3>
      <p>
        <b>土曜日の練習</b>で使うことのある建物です。
      </p>

      <h3>
        <ExternalLink to={campusPlazaBUrl}>キャンパスプラザ</ExternalLink>
      </h3>
      <p>
        コミュニケーションプラザの北東にある建物。ユリゼンの部室はここのB302にあります。
      </p>
      <p>
        授業の合間や休み時間にはユリゼンの団員がいて、自主練習やおしゃべりをしています。
      </p>

      <h2>
        <ExternalLink to={uguisudaniSakuraUrl}>鶯谷さくら幼稚園</ExternalLink>
      </h2>
      <p>
        <b>土曜日の練習</b>で使うことのある施設です。
      </p>
      <p>渋谷に近く、練習の後すぐにアフターに行けるので便利です。</p>
      <p>
        <strong>アクセス方法</strong>
      </p>
      <p>JR渋谷駅南口、京王渋谷駅中央口から徒歩約15分。</p>
      <p>
        ちょっと分かりづらいですが、はじめのうちはご連絡いただければ
        団員が渋谷駅まで看板を持ってお迎えにあがりますのでご安心ください。
      </p>
    </>
  );
};

export default memo(Access);
export const accessPath = 'access';
export const accessUrl = `/${accessPath}`;
