import { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { homeUrl } from './Home';

const Error = (): React.ReactElement => {
  useEffect(() => {
    document.title = '東京大学混声合唱団コール・ユリゼン';
  }, []);
  return (
    <>
      お探しのページは見つかりませんでした。
      <br />
      <Link to={homeUrl}>トップページへ</Link>
    </>
  );
};

export default memo(Error);
