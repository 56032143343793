import { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { aboutUsUrl } from './AboutUs';
import { visitFormUrl } from './Contact';
import { practiceScheduleUrl } from './Schedule';
import ExternalLink from '../components/ExternalLink';
import Img from '../components/Img';
import RedText from '../components/RedText';
import TwitterTimeline from '../components/TwitterTimeline';
import YouTube from '../components/YouTube';
import flyer61 from '../images/flyer_61.jpg';
import izanai2020 from '../images/izanai2020_cover.jpg';

export const izanaiUrl = `${process.env.PUBLIC_URL}/files/izanai2020.pdf`;
export const twitterId = 'Chor_Urizen';
export const twitterUrl = `https://twitter.com/${twitterId}`;
export const youtubeUrl =
  'https://www.youtube.com/channel/UCqFBlbO2BQkFlk1AJy4dUVw';

const Home = (): React.ReactElement => {
  useEffect(() => {
    document.title = '東京大学混声合唱団コール・ユリゼン';
  }, []);
  return (
    <>
      <h1>東京大学混声合唱団コール・ユリゼン</h1>
      <h3>最後の定期演奏会を行いました</h3>
      <h4>第61回定期演奏会</h4>
      <p>
        2021年12月25日(土)、コール・ユリゼンとして最後となる定期演奏会を実施しました。たくさんの方のご来場、誠にありがとうございました。
      </p>
      <Img src={flyer61} alt="第61回定演チラシ" size="medium" />

      <h3>コール・ユリゼンについて</h3>
      <p>
        <strong>
          1954年に設立した東京大学混声合唱団コール・ユリゼンは、2021年度をもって
          <RedText>当面の活動を停止</RedText>
          しました。長い間ありがとうございました。
        </strong>
      </p>
      <p>以下は活動停止前の情報となります。</p>
      <p>
        東京大学混声合唱団コール・ユリゼンのWebサイトを見に来てくださってありがとうございます。
      </p>
      <p>
        コール・ユリゼンは、東大駒場キャンパスを拠点としたインカレ合唱団です。
        東京大学生を中心に、様々な大学の学生で構成されています。（所属可能な大学の指定はありません！）
        ポップスやミュージカルを含む幅広い楽曲を扱い、振り付けありの合唱もしています。
      </p>
      <p>
        新入生はいつでも大歓迎です！ 練習予定は
        <Link to={practiceScheduleUrl}>こちら</Link>
        をご覧ください。見学・参加をご希望の方は、当日までに
        <ExternalLink to={visitFormUrl}>見学希望フォーム</ExternalLink>
        または
        <ExternalLink to={twitterUrl}>Twitter</ExternalLink>
        のDMでお問い合わせください。
      </p>
      {/* <p>COVID-19の状況下では、このようなリモート合唱にも取り組みました！</p>
      <YouTube videoId="EVPLDge5oQM" /> */}
      <p>
        ユリゼンの演奏は
        <ExternalLink to={youtubeUrl}>YouTube</ExternalLink>
        でご覧いただけます。
      </p>
      <YouTube videoId="Z8nvr4cGo48" />

      <h3>新入生・見学希望の方へ</h3>
      <p>
        新歓期配布冊子「
        <ExternalLink to={izanaiUrl}>いざない</ExternalLink>
        」もどうぞ。
      </p>
      <Img
        src={izanai2020}
        alt="新歓冊子「いざない」の表紙"
        size="medium"
        externalTo={izanaiUrl}
      />
      <p>
        「<Link to={aboutUsUrl}>コール・ユリゼンとは</Link>
        」からご覧ください。
      </p>
      <p>
        Xは<ExternalLink to={twitterUrl}>こちら</ExternalLink>。
      </p>
      <TwitterTimeline screenName={twitterId} />
    </>
  );
};

export default memo(Home);
export const homePath = '';
export const homeUrl = `/${homePath}`;
