import {
  faBars,
  faCircleInfo,
  faComment,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CSSProperties,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';

import logoSmall from '../images/logo_small.png';
import { accessUrl } from '../pages/Access';
import { contactUrl } from '../pages/Contact';
import { homeUrl } from '../pages/Home';
import { policyUrl } from '../pages/Policy';
import { sitemapUrl } from '../pages/Sitemap';

export type Props = {
  children?: ReactNode;
};

const MobileMenubar = ({ children: sidebar }: Props): React.ReactElement => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [infoOpen, setInfoOpen] = useState<boolean>(false);
  const [contactOpen, setContactOpen] = useState<boolean>(false);
  const spacerStyle: CSSProperties = {
    width: '100%',
    height: '45px',
    backgroundColor: 'var(--orange)',
  };
  const buttonContainerStyle: CSSProperties = {
    ...spacerStyle,
    position: 'fixed',
    background: 'linear-gradient(#ebc8a0, var(--orange), #c84600)',
    boxShadow: '0px 2px 10px 0px var(--translucent)',
    color: 'var(--light-orange)',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'row',
  };
  const buttonStyle: CSSProperties = {
    width: 'fit-content',
    padding: '5px',
    fontSize: '20px',
    textAlign: 'center',
    zIndex: 4,
  };
  const buttonTextStyle: CSSProperties = {
    fontWeight: 'bold',
    fontFamily: 'var(--bold-font-family)',
    fontSize: '10px',
  };
  const buttonSpacerStyle: CSSProperties = {
    flexGrow: 1,
  };
  const sidebarContainerStyle: CSSProperties = {
    position: 'fixed',
    height: '100%',
    borderRightStyle: menuOpen ? 'solid' : 'none',
    borderRightWidth: '4px',
    borderRightColor: 'var(--orange)',
    zIndex: 4,
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'scroll',
    width: menuOpen ? '230px' : 0,
    transition: '.3s',
  };
  const sidebarStyle: CSSProperties = {
    flexGrow: 1,
    backgroundColor: 'var(--light-orange)',
  };
  const logoContainerStyle: CSSProperties = {
    width: '230px',
    height: spacerStyle.height,
    background: buttonContainerStyle.background,
    borderBottomStyle: 'solid',
    borderBottomWidth: '4px',
    borderBottomColor: 'var(--orange)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const infoStyle: CSSProperties = {
    position: 'fixed',
    top: spacerStyle.height,
    right: '30px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: infoOpen ? '60px' : 0,
    zIndex: 3,
    transition: sidebarContainerStyle.transition,
  };
  const contactStyle: CSSProperties = {
    ...infoStyle,
    right: 0,
    height: contactOpen ? '60px' : 0,
  };
  const linkStyle: CSSProperties = {
    display: 'block',
    height: '100%',
    width: '100%',
    backgroundColor: 'var(--orange)',
    color: 'var(--white)',
    fontWeight: 'bold',
    fontFamily: 'var(--bold-font-family)',
    marginTop: '2px',
    lineHeight: '28px',
    paddingLeft: '10px',
    paddingRight: '10px',
  };
  const menuBackdropStyle: CSSProperties = {
    position: 'fixed',
    width: '100%',
    height: '100%',
    display: menuOpen ? 'block' : 'none',
    zIndex: 3,
    backgroundColor: 'var(--translucent)',
  };
  const linksBackdropStyle: CSSProperties = {
    position: 'fixed',
    width: '100%',
    height: '100%',
    display: infoOpen || contactOpen ? 'block' : 'none',
    zIndex: 1,
    backgroundColor: 'var(--translucent)',
  };
  const close = useCallback(() => {
    setMenuOpen(false);
    setInfoOpen(false);
    setContactOpen(false);
  }, []);
  const location = useLocation();
  useEffect(() => {
    close();
  }, [close, location]);
  const onClickMenu = () => {
    setMenuOpen(true);
    setInfoOpen(false);
    setContactOpen(false);
  };
  const onClickInfo = () => {
    setInfoOpen(true);
    setMenuOpen(false);
    setContactOpen(false);
  };
  const onClickContact = () => {
    setContactOpen(true);
    setMenuOpen(false);
    setInfoOpen(false);
  };
  return (
    <>
      <div style={spacerStyle} />
      <div style={buttonContainerStyle}>
        <div onClick={onClickMenu} style={buttonStyle}>
          <FontAwesomeIcon icon={faBars} />
          <div style={buttonTextStyle}>MENU</div>
        </div>
        <div style={buttonSpacerStyle} />
        <div onClick={onClickInfo} style={buttonStyle}>
          <FontAwesomeIcon icon={faCircleInfo} />
          <div style={buttonTextStyle}>INFO</div>
        </div>
        <div onClick={onClickContact} style={buttonStyle}>
          <FontAwesomeIcon icon={faComment} />
          <div style={buttonTextStyle}>CONTACT</div>
        </div>
      </div>
      <div style={sidebarContainerStyle}>
        <div style={sidebarStyle}>
          <Link to={homeUrl}>
            <div style={logoContainerStyle}>
              <img src={logoSmall} alt="Chor Urizen" />
            </div>
          </Link>
          {sidebar}
        </div>
      </div>
      <div style={infoStyle}>
        <Link to={sitemapUrl} style={linkStyle}>
          サイトマップ
        </Link>
        <Link to={policyUrl} style={linkStyle}>
          本サイトについて
        </Link>
      </div>
      <div style={contactStyle}>
        <Link to={accessUrl} style={linkStyle}>
          活動場所
        </Link>
        <Link to={contactUrl} style={linkStyle}>
          お問い合わせ
        </Link>
      </div>
      <div style={menuBackdropStyle} onClick={close} />
      <div style={linksBackdropStyle} onClick={close} />
    </>
  );
};

export default memo(MobileMenubar);
