export const practiceScheduleData: Array<{
  event?: string;
  date?: string;
  time?: string;
  place?: string;
}> = [
  // example below
  // {
  //   event: '通常練習',
  //   date: '11/2（土）',
  //   time: '15:00-18:00',
  //   place: '学生会館215',
  // },
  // {
  //   event: '通常練習',
  //   date: '11/6（水）',
  //   time: '17:00-20:00',
  //   place: '舞台芸術実習室',
  // },
  // {
  //   event: '通常練習',
  //   date: '11/9（土）',
  //   time: '15:00-18:00',
  //   place: '学生会館215',
  // },
];
