import { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { concertPath } from './Concert';
import { practiceSchedulePath } from './PracticeSchedule';

const Schedule = (): React.ReactElement => {
  useEffect(() => {
    document.title = 'スケジュール | 東京大学混声合唱団コール・ユリゼン';
  }, []);
  return (
    <>
      <h1>スケジュール</h1>
      <p>ユリゼンの活動スケジュールについて随時更新してお知らせします。</p>
      <p>見学時の参考としてご活用ください。</p>
      <h3>
        <Link to={concertPath}>演奏会</Link>
      </h3>
      <p>演奏会の予定についてお知らせします。</p>
      <h3>
        <Link to={practiceSchedulePath}>練習日程</Link>
      </h3>
      <p>ユリゼンの練習の予定についてお知らせします。</p>
    </>
  );
};

export default memo(Schedule);
export const schedulePath = 'schedule';
export const scheduleUrl = `/${schedulePath}`;
export const concertUrl = `/${schedulePath}/${concertPath}`;
export const practiceScheduleUrl = `/${schedulePath}/${practiceSchedulePath}`;
