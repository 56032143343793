import { memo, ReactNode } from 'react';

export type Props = {
  children?: ReactNode;
};

const RedText = ({ children }: Props): React.ReactElement => {
  return <span style={{ color: 'red' }}>{children}</span>;
};

export default memo(RedText);
