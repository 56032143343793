import { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { eventsPath } from './Events';
import { greetingsPath } from './Greetings';
import { joinPath } from './Join';
import { practicePath } from './Practice';
import ExternalLink from '../../components/ExternalLink';
import RedText from '../../components/RedText';
import { accessUrl } from '../Access';
import { izanaiUrl } from '../Home';

const AboutUs = (): React.ReactElement => {
  useEffect(() => {
    document.title =
      'コール・ユリゼンとは | 東京大学混声合唱団コール・ユリゼン';
  }, []);
  return (
    <>
      <h1>ユリゼンへようこそ</h1>
      <p>
        <strong>
          1954年に設立した東京大学混声合唱団コール・ユリゼンは、2021年度をもって
          <RedText>当面の活動を停止</RedText>
          しました。長い間ありがとうございました。
        </strong>
      </p>
      <p>以下は活動停止前の情報となります。</p>

      <h2>ユリゼンとは</h2>
      <h3>団員構成</h3>
      <p>コール・ユリゼンは総勢60名ほどからなるインカレの混声合唱団です。</p>
      <p>
        男女比は、現在は女性のほうが少し多めで、男性は東大生、女性は大学生の方ならどなたでも入ることができます。たとえば女性は東大の他、昭和女子、東京女子、清泉女子などの女子大や明治、立教などの共学からも来ています。
      </p>

      <h3>練習</h3>
      <p>
        練習は水曜と土曜の週2回。勉強やアルバイト、他のサークルとの掛け持ちなど、大学生だからいろいろやりたいという方も、週2回なら無理なく練習に参加することができます。練習場所については
        <Link to={accessUrl}>こちら</Link>
        をご参照ください。
      </p>

      <h2>ユリゼンの魅力</h2>
      <p>
        さまざまな人がこんなに大勢集うほどのユリゼンの魅力って何なのでしょうか？
      </p>
      <p>
        毎年卒団していく先輩方からは口々に「ユリゼンのあたたかい雰囲気に惹かれた」という言葉が自然とあふれてきます。
      </p>
      <p>
        現在ユリゼンに所属する団員はみんな歌うことが大好きなのですが、全員が最初から歌うことが好きだったというわけではないようです。もちろん歌うことが楽しいからという理由で入ってくる人も多いですが、中には歌うことが苦手にも関わらずユリゼンに入ってくる人もいます。
      </p>
      <p>
        歌が好きな人も、歌が苦手だった人も、みんなをユリゼンに惹きつけたもの ──
        それが「ユリゼンのあたたかい雰囲気」であり、私たちの誇りとするものでもあると思います。そんなユリゼンのあたたかさは実際に見学に来て肌で感じてみないと分からないでしょう。歌が好きな人も、これから歌を好きになりたい人も、ぜひ一度見学にいらしてください。団員一同、いつでもあたたかくあなたを歓迎いたします。
      </p>

      <h2>ユリゼンについてもっと知りたい方へ</h2>
      <h3>
        <ExternalLink to={izanaiUrl}>いざない</ExternalLink>
      </h3>
      <p>
        いざないとは新歓期に新入生向けに配布している冊子です。去年の入団員が中心となって作ったもので、ユリゼンについて分かりやすく丁寧にまとめられています。
      </p>

      <h3>
        <Link to={greetingsPath}>ご挨拶</Link>
      </h3>
      <p>ユリゼンの代表者からのメッセージです。</p>

      <h3>
        <Link to={practicePath}>練習内容</Link>
      </h3>
      <p>普段の練習の流れを分かりやすく時間を追ってご説明します。</p>

      <h3>
        <Link to={eventsPath}>イベント</Link>
      </h3>
      <p>
        年間での大きなイベントである定期演奏会、夏合宿、春合宿のご紹介や、ユリゼンのもう一つの魅力であるキャンプなどの楽しく遊べる企画についてもご説明します。
      </p>

      <h3>
        <Link to={joinPath}>見学、入団するには</Link>
      </h3>
      <p>新歓期についての情報や、入団のご案内についてご説明します。</p>
    </>
  );
};

export default memo(AboutUs);
export const aboutUsPath = 'aboutus';
export const aboutUsUrl = `/${aboutUsPath}`;
export const greetingsUrl = `/${aboutUsPath}/${greetingsPath}`;
export const practiceUrl = `/${aboutUsPath}/${practicePath}`;
export const eventsUrl = `/${aboutUsPath}/${eventsPath}`;
export const joinUrl = `/${aboutUsPath}/${joinPath}`;
