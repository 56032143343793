import { CSSProperties, memo, useEffect } from 'react';

import { practiceScheduleData } from './data/practiceScheduleData';
import ExternalLink from '../../components/ExternalLink';
import { visitFormUrl } from '../Contact';
import { twitterUrl } from '../Home';

const tableStyle: CSSProperties = {
  width: '100%',
  textAlign: 'center',
};
const trStyle: CSSProperties = {
  border: 'solid 1px var(--orange)',
};
const tdStyle: CSSProperties = {
  border: 'unset',
};

const PracticeSchedule = (): React.ReactElement => {
  useEffect(() => {
    document.title =
      '練習日程 | ユリゼンデータ | 東京大学混声合唱団コール・ユリゼン';
  }, []);
  return (
    <>
      <h1>練習日程</h1>
      <p>
        ユリゼンの活動日程表です。 参加してみたい！という方は、当日までに
        <ExternalLink to={visitFormUrl}>見学希望フォーム</ExternalLink>
        または
        <ExternalLink to={twitterUrl}>Twitter</ExternalLink>
        のDMでお問い合わせください。
      </p>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th>イベント</th>
            <th>日付</th>
            <th>時間</th>
            <th>場所</th>
          </tr>
        </thead>
        <tbody>
          {practiceScheduleData.map((p, i) => (
            <tr key={i} style={trStyle}>
              <td style={tdStyle}>{p.event}</td>
              <td style={tdStyle}>{p.date}</td>
              <td style={tdStyle}>{p.time}</td>
              <td style={tdStyle}>{p.place}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default memo(PracticeSchedule);
export const practiceSchedulePath = 'pschedule';
