import { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  aboutUsUrl,
  eventsUrl,
  greetingsUrl,
  joinUrl,
  practiceUrl,
} from './AboutUs';
import { accessUrl } from './Access';
import { communityUrl } from './Community';
import { contactUrl } from './Contact';
import { homeUrl } from './Home';
import { linksUrl } from './Links';
import { policyUrl } from './Policy';
import { concertUrl, practiceScheduleUrl, scheduleUrl } from './Schedule';
import {
  frameworkUrl,
  historyUrl,
  teachersUrl,
  urizenDataUrl,
} from './UrizenData';

const Sitemap = (): React.ReactElement => {
  useEffect(() => {
    document.title = 'サイトマップ | 東京大学混声合唱団コール・ユリゼン';
  }, []);
  return (
    <>
      <h1>サイトマップ</h1>
      <table className="unset">
        <tbody>
          <tr>
            <td>
              ▼ <Link to={homeUrl}>トップページ</Link>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>
              ▼ <Link to={aboutUsUrl}>コール・ユリゼンとは</Link>
            </td>
            <td>主に新入生向けにユリゼンについてご紹介します。</td>
          </tr>
          <tr>
            <td>
              ┣ <Link to={greetingsUrl}>ご挨拶</Link>
            </td>
            <td>ユリゼンの代表者からのメッセージです。</td>
          </tr>
          <tr>
            <td>
              ┣ <Link to={practiceUrl}>練習内容</Link>
            </td>
            <td>普段の練習の流れを時間を追ってご説明します。</td>
          </tr>
          <tr>
            <td>
              ┣ <Link to={eventsUrl}>イベント</Link>
            </td>
            <td>合宿やキャンプ、定期演奏会などのイベントのご説明です。</td>
          </tr>
          <tr>
            <td>
              ┗ <Link to={joinUrl}>入団するには</Link>
            </td>
            <td>新歓期についての情報や、入団のご案内を説明します。</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>
              ▼ <Link to={urizenDataUrl}>ユリゼンデータ</Link>
            </td>
            <td>一般向けにオフィシャルな情報を公開しています。</td>
          </tr>
          <tr>
            <td>
              ┣ <Link to={teachersUrl}>先生の紹介</Link>
            </td>
            <td>ユリゼンを支えてくださっている6名の先生の紹介です。</td>
          </tr>
          <tr>
            <td>
              ┣ <Link to={frameworkUrl}>組織体制</Link>
            </td>
            <td>ユリゼンという組織の運営体制のご紹介です。</td>
          </tr>
          <tr>
            <td>
              ┗ <Link to={historyUrl}>歴史</Link>
            </td>
            <td>過去の定期演奏会の情報を掲載しています。</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>
              ▼ <Link to={scheduleUrl}>スケジュール</Link>
            </td>
            <td>ユリゼンの活動スケジュールをお知らせします。</td>
          </tr>
          <tr>
            <td>
              ┣ <Link to={concertUrl}>演奏会</Link>
            </td>
            <td>合唱祭や定期演奏会などの予定についてのお知らせです。</td>
          </tr>
          <tr>
            <td>
              ┗ <Link to={practiceScheduleUrl}>練習日程</Link>
            </td>
            <td>ユリゼンの練習の予定についてのお知らせです。</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>
              ▼ <Link to={communityUrl}>コミュニティ</Link>
            </td>
            <td>OBOGや一般のみなさまとの交流についてのご案内です。</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>
              ▼ <Link to={linksUrl}>リンク</Link>
            </td>
            <td>ユリゼンと関係のある合唱団などのご紹介です。</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>
              ▽ <Link to=".">サイトマップ</Link>
            </td>
            <td>このページです。</td>
          </tr>
          <tr>
            <td>
              ▽ <Link to={policyUrl}>本サイトについて</Link>
            </td>
            <td>このサイトの著作権や制作にまつわる話です。</td>
          </tr>
          <tr>
            <td>
              ▽ <Link to={accessUrl}>活動場所</Link>
            </td>
            <td>ユリゼンの活動場所のご案内です。</td>
          </tr>
          <tr>
            <td>
              ▽ <Link to={contactUrl}>お問い合わせ</Link>
            </td>
            <td>{/* 見学希望やその他 */}お問い合わせはこちらから。</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default memo(Sitemap);
export const sitemapPath = 'sitemap';
export const sitemapUrl = `/${sitemapPath}`;
