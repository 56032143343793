import { CSSProperties, memo, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { historyData } from './data/historyData';
import { contactUrl } from '../Contact';

const tdNumberStyle: CSSProperties = { textAlign: 'center' };
const tdDateStyle: CSSProperties = { ...tdNumberStyle };
const tdPlaceStyle: CSSProperties = { width: '20%' };
const tableUnsetStyle: CSSProperties = { border: 'unset' };
const tdUnsetStyle: CSSProperties = { border: 'unset' };

const History = (): React.ReactElement => {
  useEffect(() => {
    document.title =
      '歴史 | ユリゼンデータ | 東京大学混声合唱団コール・ユリゼン';
  }, []);
  return (
    <>
      <h1>歴史</h1>
      <p>
        ここではユリゼンの過去の定期演奏会の情報を掲載しています。
        ユリゼンは、実は60年以上もの長い伝統を持つサークルなのです。
      </p>
      <p>
        ※ 欠けている部分の情報をご存知の方がいらっしゃいましたら、
        <Link to={contactUrl}>メール</Link>などで教えていただけると助かります。
      </p>
      <table>
        <tbody>
          <tr>
            <th>回</th>
            <th>日付</th>
            <th>場所</th>
            <th>曲目</th>
            <th>指揮</th>
          </tr>
          {historyData.map((h, i) => (
            <tr key={i}>
              <td style={tdNumberStyle}>{h.number}</td>
              <td style={tdDateStyle}>{h.date}</td>
              <td style={tdPlaceStyle}>{h.place}</td>
              <td>
                <table style={tableUnsetStyle}>
                  <tbody>
                    {h.setlist.map((s, j) => (
                      <tr key={j}>
                        {s.map((e, k) => (
                          <td key={k} style={tdUnsetStyle}>
                            {e}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
              <td>
                {h.conductors.map((c, i) => (
                  <p key={i}>{c}</p>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default memo(History);
export const historyPath = 'history';
