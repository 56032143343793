import { memo, useEffect } from 'react';

import ExternalLink from '../../components/ExternalLink';

const Links = (): React.ReactElement => {
  useEffect(() => {
    document.title = 'リンク | 東京大学混声合唱団コール・ユリゼン';
  }, []);
  return (
    <>
      <h1>リンク</h1>
      {/* <h2>合唱団関係</h2> */}
      <h3>
        <ExternalLink to="https://twitter.com/lunlun_bibibi">
          武蔵野大学ルンビニー合唱団
        </ExternalLink>
      </h3>
      <p>
        ユリゼンの常任指揮者である山口先生が同じように指導なさっている合唱団です。
        ジョイントコンサートで一緒に歌ったり、合同での食事会があったりするなど、
        特にユリゼンと交流の深い合唱団です。
      </p>
      <h3>
        <s>
          <ExternalLink to="http://chorohbierren.com/">
            コール・オービーレン
          </ExternalLink>
        </s>
      </h3>
      <p>
        卒業後も山口先生のもとで歌いたいOBOGの集まる合唱団です。
        実はユリゼンに限らず様々な団体のOBOGが所属しているようです。
      </p>
      <h3>
        <ExternalLink to="https://wasedachorus.com/">
          早稲田大学合唱団
        </ExternalLink>
      </h3>
      <p>
        早稲田大学の合唱団です。一緒に食事をしたりして、毎年のように交流しています。
      </p>
      <h3>
        <ExternalLink to="https://www.midorikai-chorus.com/">
          東京大学法学部緑会合唱団
        </ExternalLink>
      </h3>
      <p>
        同じ東京大学の合唱団です。
        合同での飲み会などを通じて、近年交流を深めています。
      </p>
      <h3>
        <ExternalLink to="http://shirobarakai.com/">
          東京大学白ばら会合唱団
        </ExternalLink>
      </h3>
      <p>同じ東京大学の合唱団です。 ユリゼンと部室を共有しています。</p>
      <h3>
        <ExternalLink to="http://hakuyokai.com/">柏葉会合唱団</ExternalLink>
      </h3>
      <p>同じ東京大学の合唱団です。</p>

      {/* <h2>その他</h2> */}
    </>
  );
};

export default memo(Links);
export const linksPath = 'links';
export const linksUrl = `${linksPath}`;
