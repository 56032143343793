import { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { frameworkPath } from './Framework';
import { historyPath } from './History';
import { teachersPath } from './Teachers';

const UrizenData = (): React.ReactElement => {
  useEffect(() => {
    document.title = 'ユリゼンデータ | 東京大学混声合唱団コール・ユリゼン';
  }, []);
  return (
    <>
      <h1>ユリゼンデータ</h1>
      <p>ここでは一般向けに少しオフィシャルな情報をお伝えします。</p>
      <h3>
        <Link to={teachersPath}>先生の紹介</Link>
      </h3>
      <p>ユリゼンを支えてくださっている6名の先生のご紹介です。</p>

      <h3>
        <Link to={frameworkPath}>組織体制</Link>
      </h3>
      <p>ユリゼンという組織がどのように運営されているかをご紹介しています。</p>

      <h3>
        <Link to={historyPath}>歴史</Link>
      </h3>
      <p>ユリゼンの第1回からの過去の定期演奏会の情報です。</p>
    </>
  );
};

export default memo(UrizenData);
export const urizenDataPath = 'data';
export const urizenDataUrl = `/${urizenDataPath}`;
export const teachersUrl = `/${urizenDataPath}/${teachersPath}`;
export const frameworkUrl = `/${urizenDataPath}/${frameworkPath}`;
export const historyUrl = `/${urizenDataPath}/${historyPath}`;
