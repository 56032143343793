import { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';

import ExternalLink from '../../components/ExternalLink';
import RedText from '../../components/RedText';
import TwitterTimeline from '../../components/TwitterTimeline';
import { contactUrl } from '../Contact';
import { twitterId, twitterUrl } from '../Home';

const Community = (): React.ReactElement => {
  useEffect(() => {
    document.title = 'コミュニティ | 東京大学混声合唱団コール・ユリゼン';
  }, []);
  return (
    <>
      <h1>コミュニティ</h1>
      <p>
        <strong>
          ※以前設置されていた掲示板およびOBOG交流スペースは利用者減少に伴い
          <RedText>閉鎖</RedText>しました。
        </strong>
      </p>
      <p>
        <strong>
          交流は<Link to={contactUrl}>メール</Link>や
          <ExternalLink to={twitterUrl}>X</ExternalLink>
          でお待ちしております。
        </strong>
      </p>
      <TwitterTimeline screenName={twitterId} />
    </>
  );
};

export default memo(Community);
export const communityPath = 'community';
export const communityUrl = `/${communityPath}`;
