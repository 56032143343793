import { useState, useEffect, useCallback, useRef, memo } from 'react';

export type Props = {
  videoId: string;
  autoPlay?: boolean;
  title?: string;
};

const YouTube = ({ videoId, autoPlay, title }: Props): React.ReactElement => {
  const videoUrl = `https://www.youtube.com/embed/${videoId}${
    autoPlay ? '?autoplay=1' : ''
  }`;
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const defaultHeight = 495;
  const [videoHeight, setVideoHeight] = useState<number>(
    iframeRef.current ? iframeRef.current.offsetWidth * 0.5625 : defaultHeight
  );
  const handleChangeVideoWidth = useCallback(() => {
    const height = iframeRef.current
      ? iframeRef.current.offsetWidth * 0.5625
      : defaultHeight;
    setVideoHeight(Math.floor(height));
  }, []);

  useEffect(() => {
    handleChangeVideoWidth();
    window.addEventListener('resize', handleChangeVideoWidth);
    return () => {
      window.removeEventListener('resize', handleChangeVideoWidth);
    };
  }, [videoHeight, handleChangeVideoWidth]);

  return (
    <iframe
      ref={iframeRef}
      title={title ?? 'YouTube'}
      width="100%"
      height={`${videoHeight}px`}
      src={videoUrl}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
      style={{ border: 'none' }}
    />
  );
};

export default memo(YouTube);

// https://codesandbox.io/s/5boon?file=/src/YoutubeIframe.tsx
