import { memo, useEffect } from 'react';

const Greetings = (): React.ReactElement => {
  useEffect(() => {
    document.title =
      'ご挨拶 | コール・ユリゼンとは | 東京大学混声合唱団コール・ユリゼン';
  }, []);
  return (
    <>
      <h1>ご挨拶</h1>
      <p>こんにちは！私たちは東京大学混声合唱団コール・ユリゼンです。</p>
      <p>
        ユリゼンは、東大を中心に様々な大学からメンバーが集まっているインカレの合唱団です。合唱サークルですが、単に合唱曲だけを歌っているわけではなく、ミサ曲やミュージカル曲など様々なジャンルの曲を歌っています。歌い方も様々で、振り付けをしたり小道具を使ったり多様な形で演奏しています。いわゆる「合唱」という枠にとらわれることはありません。
      </p>
      <p>
        そんな中で私たちが常に意識していることがあります。それは「楽しく歌う」ということです。どうすれば自分もまわりも楽しく演奏できるかということを考えながら、日々の活動に取り組んでいます。
      </p>
      <p>
        ユリゼンには、音楽をやったことがある人もない人も、いろいろな人が集まっています。
      </p>
      <p>
        ユリゼンは、いつでも見学・入団をお待ちしています！このホームページを見て、少しでも気になったあなた！ぜひ、一緒に歌いませんか？
      </p>
    </>
  );
};

export default memo(Greetings);
export const greetingsPath = 'greetings';
