import { CSSProperties, memo, useEffect } from 'react';

import { frameworkData } from './data/frameworkData';

const tdTitleStyle: CSSProperties = {
  fontWeight: 'bold',
  fontFamily: 'var(--bold-font-family)',
  fontSize: '16px',
  color: 'var(--white)',
  backgroundColor: 'var(--orange)',
  textAlign: 'center',
  padding: '10px',
};
const tdRoleStyle: CSSProperties = {
  backgroundColor: 'var(--light-orange)',
};

const Framework = (): React.ReactElement => {
  useEffect(() => {
    document.title =
      '組織体制 | ユリゼンデータ | 東京大学混声合唱団コール・ユリゼン';
  }, []);
  return (
    <>
      <h1>組織体制</h1>
      <p>
        ユリゼンは執行代である3年生と、常任代である2年生を中心として、組織を運営しています。
        以下におおよその組織体制をご紹介します。
      </p>
      <p>
        1年生にも役割があります。それは歌うことを、そしてユリゼンを楽しむことです♪
      </p>
      <table>
        <tbody>
          {frameworkData.map((d) => (
            <>
              <tr>
                <td colSpan={2} style={tdTitleStyle}>
                  {d.title}
                </td>
              </tr>
              {d.roles.map((r) => (
                <tr>
                  <td style={tdRoleStyle}>{r.role}</td>
                  <td>{r.description}</td>
                </tr>
              ))}
            </>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default memo(Framework);
export const frameworkPath = 'framework';
