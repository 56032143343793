import { CSSProperties, memo, useState } from 'react';
import { Link } from 'react-router-dom';

import LinkIf from './LinkIf';
import aboutUsImage from '../images/sidebar_about_us.jpg';
import communityImage from '../images/sidebar_community.jpg';
import linksImage from '../images/sidebar_links.jpg';
import scheduleImage from '../images/sidebar_schedule.jpg';
import urizenDataImage from '../images/sidebar_urizen_data.jpg';
import {
  aboutUsUrl,
  eventsUrl,
  greetingsUrl,
  joinUrl,
  practiceUrl,
} from '../pages/AboutUs';
import { communityUrl } from '../pages/Community';
import { linksUrl } from '../pages/Links';
import {
  concertUrl,
  practiceScheduleUrl,
  scheduleUrl,
} from '../pages/Schedule';
import {
  frameworkUrl,
  historyUrl,
  teachersUrl,
  urizenDataUrl,
} from '../pages/UrizenData';

const keys = [
  'aboutUs',
  'urizenData',
  'schedule',
  'community',
  'links',
] as const;
type Key = typeof keys[number];
const urls: {
  [key in Key]: {
    title: string;
    url: string;
    image: string;
    description: string[];
    children: Array<{ title: string; url: string }>;
  };
} = {
  aboutUs: {
    title: 'コール・ユリゼンとは',
    url: aboutUsUrl,
    image: aboutUsImage,
    description: ['新入生向けの情報が満載！', 'まずはこちらから。'],
    children: [
      { title: 'ご挨拶', url: greetingsUrl },
      { title: '練習内容', url: practiceUrl },
      { title: 'イベント', url: eventsUrl },
      { title: '入団するには', url: joinUrl },
    ],
  },
  urizenData: {
    title: 'ユリゼンデータ',
    url: urizenDataUrl,
    image: urizenDataImage,
    description: ['組織体制や歴史など、', 'ちょっと正式なお話です。'],
    children: [
      { title: '先生の紹介', url: teachersUrl },
      { title: '組織体制', url: frameworkUrl },
      { title: '歴史', url: historyUrl },
    ],
  },
  schedule: {
    title: 'スケジュール',
    url: scheduleUrl,
    image: scheduleImage,
    description: ['演奏会や練習の日程を', 'お知らせします。'],
    children: [
      { title: '演奏会', url: concertUrl },
      { title: '練習日程', url: practiceScheduleUrl },
    ],
  },
  community: {
    title: 'コミュニティ',
    url: communityUrl,
    image: communityImage,
    description: ['OBOGや一般のみなさまとの', '交流についてのご案内です。'],
    children: [],
  },
  links: {
    title: 'リンク',
    url: linksUrl,
    image: linksImage,
    description: ['ユリゼンと関係のある', '合唱団などのご紹介です。'],
    children: [],
  },
};

const Sidebar = (): React.ReactElement => {
  const [aboutUsOpen, setAboutUsOpen] = useState<boolean>(false);
  const [urizenDataOpen, setUrizenDataOpen] = useState<boolean>(false);
  const [scheduleOpen, setScheduleOpen] = useState<boolean>(false);
  const [communityOpen, setCommunityOpen] = useState<boolean>(false);
  const [linksOpen, setLinksOpen] = useState<boolean>(false);
  const open = {
    aboutUs: aboutUsOpen,
    urizenData: urizenDataOpen,
    schedule: scheduleOpen,
    community: communityOpen,
    links: linksOpen,
  };
  const setOpen = {
    aboutUs: setAboutUsOpen,
    urizenData: setUrizenDataOpen,
    schedule: setScheduleOpen,
    community: setCommunityOpen,
    links: setLinksOpen,
  };
  const containerStyle = (
    open: boolean,
    numberOfLinks: number,
  ): CSSProperties => ({
    marginTop: '15px',
    marginLeft: '15px',
    marginRight: '15px',
    height: open ? `${(75 + 30 * numberOfLinks).toString()}px` : '75px',
    overflow: 'hidden',
    transition: '.3s',
  });
  const imageContainerStyle: CSSProperties = {
    position: 'relative',
    height: '75px',
  };
  const imageStyle: CSSProperties = {
    position: 'absolute',
  };
  const descriptionDivStyle = (open: boolean): CSSProperties => ({
    backgroundColor: 'var(--translucent)',
    position: 'absolute',
    width: '200px',
    bottom: 0,
    overflow: 'hidden',
    height: open ? '75px' : '25px',
    transition: '.3s',
    lineHeight: '25px',
    textAlign: 'center',
  });
  const titleStyle: CSSProperties = {
    margin: 0,
    border: 0,
    padding: 0,
    color: 'var(--white)',
    fontSize: '18px',
    fontWeight: 'bold',
    fontFamily: 'var(--bold-font-family)',
  };
  const descriptionStyle: CSSProperties = {
    ...titleStyle,
    fontSize: '14px',
  };
  const ulStyle: CSSProperties = {
    margin: 0,
    border: 0,
    padding: 0,
  };
  const liStyle: CSSProperties = {
    ...ulStyle,
    marginTop: '2px',
    backgroundColor: 'var(--light)',
  };
  const linkStyle: CSSProperties = {
    display: 'block',
    height: '100%',
    width: '100%',
    color: 'var(--orange)',
    fontWeight: 'bold',
    fontFamily: 'var(--bold-font-family)',
    lineHeight: '28px',
    fontSize: '16px',
    paddingLeft: '40px',
    paddingRight: '10px',
  };
  const linkIndexStyle: CSSProperties = {
    ...linkStyle,
    paddingLeft: '10px',
  };
  const onMouseEnter = (key: Key) => () => setOpen[key](true);
  const onMouseLeave = (key: Key) => () => setOpen[key](false);
  return (
    <>
      {keys.map((key, i) => (
        <div
          key={i}
          onMouseEnter={onMouseEnter(key)}
          onMouseLeave={onMouseLeave(key)}
          style={containerStyle(open[key], urls[key].children.length + 1)}
        >
          <div style={imageContainerStyle}>
            <img src={urls[key].image} alt='' style={imageStyle} />
            <div style={descriptionDivStyle(open[key])}>
              <LinkIf if={open[key]} to={urls[key].url}>
                <p style={titleStyle}>{urls[key].title}</p>
                {urls[key].description.map((line, j) => (
                  <p key={j} style={descriptionStyle}>
                    {line}
                  </p>
                ))}
              </LinkIf>
            </div>
          </div>
          <ul style={ulStyle}>
            <li style={liStyle}>
              <Link to={urls[key].url} style={linkIndexStyle}>
                ♪ {urls[key].title}
              </Link>
            </li>
            {urls[key].children.map((child, j) => (
              <li key={j} style={liStyle}>
                <Link to={child.url} style={linkStyle}>
                  {child.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
};

export default memo(Sidebar);
