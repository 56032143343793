import { memo, ReactNode } from 'react';

import ExternalLink from './ExternalLink';

export type Props = {
  if: boolean;
  to: string;
  children?: ReactNode;
};

const ExternalLinkIf = ({
  if: condition,
  to,
  children,
}: Props): React.ReactElement => {
  return condition ? (
    <ExternalLink to={to}>{children}</ExternalLink>
  ) : (
    <>{children}</>
  );
};

export default memo(ExternalLinkIf);
