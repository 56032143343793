import { memo } from 'react';
// import { useState, useEffect, useCallback, memo, CSSProperties } from 'react';
// import { Timeline } from 'react-twitter-widgets';
//
// import { useDevice } from '../hooks/useDevice';

export type Props = {
  screenName: string;
};

const TwitterTimeline = ({ screenName }: Props): React.ReactElement => {
  // const { isMobile } = useDevice();
  // const containerStyle: CSSProperties = {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   justifyContent: 'center',
  // };
  // const contentStyle: CSSProperties = {
  //   width: isMobile ? '90%' : '60%',
  // };
  // const ratio = 0.8;
  // const [timelineHeight, setTimelineHeight] = useState<number>(
  //   Math.floor(window.innerHeight * ratio)
  // );
  // const handleWindowInnerHeight = useCallback(() => {
  //   const newTimelineHeight = Math.floor(window.innerHeight * ratio);
  //   const changeRatio = newTimelineHeight / timelineHeight;
  //   const threshold = 2;
  //   if (changeRatio < 1 / threshold || changeRatio > threshold) {
  //     setTimelineHeight(newTimelineHeight);
  //   }
  // }, []);
  // useEffect(() => {
  //   window.addEventListener('resize', handleWindowInnerHeight);
  //   return () => {
  //     window.removeEventListener('resize', handleWindowInnerHeight);
  //   };
  // }, [timelineHeight, handleWindowInnerHeight]);
  // return (
  //   <div style={containerStyle}>
  //     <div style={contentStyle}>
  //       <Timeline
  //         dataSource={{
  //           sourceType: 'profile',
  //           screenName,
  //         }}
  //         options={{
  //           height: timelineHeight.toString(),
  //           lang: 'ja',
  //         }}
  //       />
  //     </div>
  //   </div>
  // );
  console.log(screenName);
  return <></>;
};

export default memo(TwitterTimeline);
