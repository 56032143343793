import { memo, ReactNode } from 'react';

export type Props = {
  to: string;
  children?: ReactNode;
};

const ExternalLink = ({ to, children }: Props): React.ReactElement => {
  return (
    <a href={to} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  );
};

export default memo(ExternalLink);
