export const historyData: Array<{
  number: string;
  date: string;
  place: string;
  setlist: string[][];
  conductors: string[];
}> = [
  {
    number: '61',
    date: '2021.12.25',
    place: '三鷹市芸術文化センター 風のホール',
    setlist: [
      ['Ⅰ', '『くちびるに歌を』(信長貴富) より'],
      ['Ⅱ', '東大ショークワイアコラボ'],
      ['Ⅲ', '『筑後川』(團伊玖磨) OBOG合同ステージ'],
    ],
    conductors: ['山口大五郎', '彌永薫子'],
  },
  {
    number: '60',
    date: '2020.12.26',
    place: '小金井宮地楽器ホール 大ホール',
    setlist: [
      ['Ⅰ', '『Messe Brève No. 7 in C』(Charles Gounod)'],
      ['Ⅱ', '『三つの不思議な仕事』(池辺晋一郎)'],
      ['Ⅲ', '「ディズニー曲集」演出ステージ'],
      ['Ⅳ', '『旅』(佐藤眞) OBOG合同ステージ'],
    ],
    conductors: ['山口大五郎', '石塚小麦'],
  },
  {
    number: '59',
    date: '2019.12.8',
    place: '埼玉会館 小ホール',
    setlist: [
      ['Ⅰ', '『A Little Jazz Mass』(Bob Chilcott) より'],
      ['Ⅱ', '『良寛相聞』(千原英喜) より'],
      ['Ⅲ', '『啄木短歌集』(高田三郎)'],
      ['Ⅳ', '「The Phantom of the Opera」'],
      ['Ⅴ', '「OBOG合同ステージ」'],
    ],
    conductors: ['山口大五郎', '向隼人', '石塚小麦'],
  },
  {
    number: '58',
    date: '2018.12.23',
    place: '国立オリンピック記念青少年総合センター カルチャー棟 大ホール',
    setlist: [
      ['Ⅰ', '「Ave Maria」'],
      ['Ⅱ', '『六つの子守歌』(池辺晋一郎)'],
      ['Ⅲ', '「The Sound of Music」「West Side Story」'],
      ['Ⅳ', '「OBOG合同ステージ ～愛唱曲集より～」'],
    ],
    conductors: ['山口大五郎', '角田侑子'],
  },
  {
    number: '57',
    date: '2017.12.2',
    place: '川口総合文化センター・リリア 音楽ホール',
    setlist: [
      ['Ⅰ', '「メンデルスゾーンとフォーレの合唱曲」'],
      ['Ⅱ', '『ワクワク』(信長貴富)'],
      ['Ⅲ', '「OBOG合同ステージ ～愛唱曲集より～」'],
      ['Ⅳ', '「Les Misérables」'],
    ],
    conductors: ['山口大五郎', '井村菜摘'],
  },
  {
    number: '56',
    date: '2016.12.24',
    place: '国立オリンピック記念青少年総合センター カルチャー棟 大ホール',
    setlist: [
      ['Ⅰ', '「クリスマスに寄せて ～ 日本語で歌うジョン・ラター」'],
      ['Ⅱ', '『アポロンの竪琴』(千原英喜)'],
      ['Ⅲ', '「ユリゼン委嘱曲をOBOGとともに」'],
      ['Ⅳ', '「The Best of Glee ～ ユリゼンらしく！」'],
    ],
    conductors: ['山口大五郎', '柳瑛一朗'],
  },
  {
    number: '55',
    date: '2016.2.11',
    place: '三鷹市芸術文化センター 風のホール',
    setlist: [
      ['Ⅰ', '『月光とピエロ』(清水脩)'],
      ['Ⅱ', '「ヨーロッパの名曲」'],
      ['Ⅲ', '『新しい歌』(信長貴富)'],
      ['Ⅳ', '「OBOG合同ステージ」'],
      ['Ⅴ', '「Mamma Mia!」'],
    ],
    conductors: ['山口大五郎', '加々美俊'],
  },
  {
    number: '54',
    date: '2014.12.21',
    place: '浅草公会堂',
    setlist: [
      ['Ⅰ', '「GLORIA」 作曲：Antonio Vivaldi'],
      [
        'Ⅱ',
        '混声合唱曲集「まだ見ぬあなたへ」 作曲：北川 昇 作詞：みなづきみのり',
      ],
      ['Ⅲ', '愛唱曲集より「乾杯の歌」「落葉松」「ひとつの朝」「流浪の民」'],
      ['Ⅳ', 'Disney Medley'],
    ],
    conductors: ['山口大五郎', '鈴木大'],
  },
  {
    number: '53',
    date: '2013.12.23',
    place: '浅草公会堂',
    setlist: [
      ['Ⅰ', '「A Little Jass Mass」 作曲：BOB CHILCOTT'],
      ['Ⅱ', '混声合唱組曲「沙羅」 作曲：信時潔'],
      ['Ⅲ', '混声合唱とピアノのための「この星の上で」 作曲：松下耕'],
      ['Ⅳ', '「ユリゼン愛唱曲集より」（OB・OG合同ステージ）'],
      ['Ⅴ', 'Les Misérables'],
    ],
    conductors: ['山口大五郎', '田島新'],
  },
  {
    number: '52',
    date: '2012.12.24',
    place: '大田区民ホール アプリコ',
    setlist: [
      ['A Ceremony of Carols (Benjamin Britten, OP.28)'],
      ['混声合唱組曲「こいうた」'],
      ['愛唱曲集より組曲「丹沢山」「はくちょう」「きよしこの夜」'],
      ['「The Sound of Music」より:'],
      ['- The Sound of Music'],
      ['- Do-Re-Mi'],
      ['- Edelweiss'],
      ["- Climb Ev'ry Mountain"],
      ['「West Side Story」より:'],
      ['- Tonight'],
      ['- I Feel Pretty'],
      ['- Maria'],
      ['- America'],
      ['- One Hand, One Heart'],
      ['- Somewhere'],
    ],
    conductors: ['山口大五郎', '片峰彬宏'],
  },
  {
    number: '51',
    date: '2011.12.24',
    place: '浅草公会堂',
    setlist: [
      ['Deutsche Messe D.872'],
      ['混声合唱組曲 富山に伝わる三つの民謡'],
      ['ＯＢＯＧと共に、クリスマスに寄せて'],
      ['ミュージカルより:'],
      ['- Mamma Mia! ("Mamma Mia!"より)'],
      ['- All I Ask Of You ("The Phantom of the Opera"より)'],
      ['- Be Our Guest ("Beauty and the Beast"より)'],
      ['- BRING HIM HOME ("Les Misérables"より)'],
      ['- AT THE END OF THE DAY ("Les Misérables"より)'],
      ['- Somewhere ("West Side Story"より)'],
      ['- DO YOU HEAR THE PEOPLE SING? ("Les Misérables"より)'],
    ],
    conductors: ['山口大五郎', '砂川健一'],
  },
  {
    number: '50',
    date: '2010.12.26',
    place: '紀尾井ホール',
    setlist: [
      ['K.317「戴冠ミサ」（モーツァルト）'],
      ['混声合唱とピアノのための「新しい歌」（信長貴富）'],
      ['混声合唱組曲「水のいのち」（高田三郎）'],
    ],
    conductors: ['山口大五郎', '飯島和之'],
  },
  {
    number: '49',
    date: '2009.12.27',
    place: '杉並公会堂',
    setlist: [
      ['「MESSE No.6」（グノー）より:'],
      ['- Kyrie'],
      ['- Gloria'],
      ['- Credo'],
      ['- Sanctus'],
      ['- O Salutaris'],
      ['- Agnus Dei'],
      ['混声合唱組曲「永久ニ」（鈴木憲夫）'],
      ['愛唱曲集より組曲「丹沢山」「はくちょう」'],
      ['「オペラ座の怪人」より:'],
      ['- The Phantom of the Opera'],
      ['- Think of Me'],
      ['- Angel of Music'],
      ['- The Phantom of the Opera'],
      ['- The Music of Night'],
      ['- All I Ask of You'],
      ['- Wishing You Were Somehow Here Again'],
      ['- Masquerade'],
      ['- The Point of No Return'],
    ],
    conductors: ['山口大五郎', '弘中明彦'],
  },
  {
    number: '48',
    date: '2008.12.21',
    place: '銀座ブロッサム',
    setlist: [
      ['- 「千曲川の水上を恋ふる歌」より「水上」（小山章三）'],
      ['- 川（橋本国彦）'],
      ['- 阿武隈川（岡本敏明）'],
      ['- 「水のいのち」より「川」（高田三郎）'],
      ['- 春の小川（岡野貞一）'],
      ['- 「筑後川」より「河口」（丸山豊）'],
      ['混声合唱組曲「世界は一冊の本」（信長貴富）'],
      ['愛唱曲集より:'],
      ['- チキ・チキ・バン・バン（シャーマン）'],
      ['- チム・チム・チェリー（シャーマン）'],
      ['- ビビディ・バビディ・ブー（リビングストン）'],
      ['- 教会へ行こう（ロエベ）'],
      ['- てなことになったらすばらしい（ロエベ）'],
      ['- Try To Remember（シュミット）'],
      ['- オブラディ・オブラダ（レノン）'],
    ],
    conductors: ['山口大五郎', '上島佑一'],
  },
  {
    number: '47',
    date: '2007.12.26',
    place: '江戸川区 総合文化センター',
    setlist: [
      ['- Magnificat（パッヘルベル）'],
      ['- Ave Maria（ビクトリア）'],
      ['- Agnus Dei（バーバー）'],
      ['混声合唱曲集「旅のかなたに」（信長貴富）'],
      ['愛唱曲集より:'],
      ['- 「宇宙戦艦ヤマト～新たなる旅立ち」より「宇宙戦艦ヤマト」'],
      ['- 「銀河鉄道999」より「出発」'],
      ['- 「アルプスの少女ハイジ」より「おしえて」'],
      ['- 宇宙海賊キャプテンハーロック」より「闘い～さすらいの舟歌」'],
      ['- 「宇宙戦艦ヤマト」より「ヤマトより愛をこめて」'],
      ['- 宇宙海賊キャプテンハーロック」より「旅立ち」'],
    ],
    conductors: ['山口大五郎', '内藤ゆかり'],
  },
  {
    number: '46',
    date: '2006.12.25',
    place: '練馬文化センター',
    setlist: [
      ['Missa in C major KV258（モーツァルト）'],
      ['混声合唱曲「永訣の朝」（鈴木憲夫）'],
      ['混声合唱のための組曲「旅」（佐藤眞）'],
      ['- オペラ「カルメン」より「ハバネラ」'],
      ['- ミュージカル「レ・ミゼラブル」より「彼を家に」'],
      ['- オペラ「トロヴァトーレ」より「Vedi! Le fosche notturne spoglie」'],
      ['- オペレッタ「メリー・ウィドウ」より「メリー・ウィドウ・ワルツ」'],
      ["- オペラ「Nabucco」より、「Va, pensiero, sull'ali dorate」"],
    ],
    conductors: ['山口大五郎', '谷田青佳'],
  },
  {
    number: '45',
    date: '2005.12.24',
    place: '大田区民ホール アプリコ',
    setlist: [
      ['Christmas Songs'],
      ['混声合唱組曲「愛のプロローグ」（高嶋みどり）'],
      ['愛唱曲集より'],
      ['ミュージカル「CATS」より'],
    ],
    conductors: ['山口大五郎', '井熊伸吾'],
  },
  {
    number: '44',
    date: '2004.12.23',
    place: 'パルテノン多摩 大ホール',
    setlist: [
      ['混声合唱組曲「心の四季」（高田三郎）'],
      ['混声合唱曲「雨ニモマケズ」（鈴木憲夫）、日本民謡集'],
      ['愛唱曲集より'],
      ['Christmas Songs'],
    ],
    conductors: ['山口大五郎', '村本玄紀'],
  },
  {
    number: '43',
    date: '2003.12.14',
    place: '練馬文化センター',
    setlist: [
      ['- 夏は来ぬ（小山作之助）'],
      ['- われは海の子'],
      ['- 里の秋（海沼実）'],
      ['- 冬の夜'],
      ['- 朧月夜（岡野貞一）'],
      ['- 春の歌（内田元）'],
      ['- 万象（山本純ノ介）'],
      ['愛唱曲集より'],
      ['カルミナ・ブラーナ（オルフ）'],
    ],
    conductors: ['山口大五郎', '山田佳代'],
  },
  {
    number: '42',
    date: '2002.12.14',
    place: '中央会館',
    setlist: [
      ['DEUTSCHE MESSE D.872(Schubert)'],
      ['- 季節へのまなざし（荻久保和明）'],
      ['- 混声合唱組曲「水のいのち」より「雨」（高田三郎）'],
      ['- 遠い雲（溝上日出夫）'],
      ['- 雪のふるまちを（中田喜直）'],
      ['- さあ太陽を呼んでこい（山本直純）'],
      ['- 混声合唱組曲「筑後川」より「河口」（團伊玖磨）'],
      ['West Side Story'],
    ],
    conductors: ['山口大五郎', '丹羽節'],
  },
  {
    number: '41',
    date: '2001.12.15',
    place: '台東区立 浅草公会堂',
    setlist: [
      ['GLORIA（ビバルディ）'],
      ['島よ（大中恩）'],
      ['丹沢山、はくちょう'],
      ['動物のカーニバル（サンサーンス、寺島尚彦）'],
    ],
    conductors: ['山口大五郎', '野木森三和子'],
  },
  {
    number: '40',
    date: '2000.12.10',
    place: '文京シビックホール',
    setlist: [
      ['戴冠ミサ（モーツァルト）'],
      ['水のいのち（高田三郎）'],
      ['愛唱曲集より'],
      ['オペラ合唱曲集'],
    ],
    conductors: ['山口大五郎', '丸尾嘉宏'],
  },
  {
    number: '39',
    date: '1999.12.18',
    place: '台東区立 浅草公会堂',
    setlist: [
      ['Messa di Gloria（プッチーニ）'],
      ['土の歌（佐藤眞）'],
      ['冬の唱歌メドレー'],
      ['平井康三郎作品集'],
      ['The Soundo of Music（ロジャース）'],
    ],
    conductors: ['山口大五郎', '熊代雅音'],
  },
  {
    number: '38',
    date: '1998.12.12',
    place: '台東区立 浅草公会堂',
    setlist: [
      ['理想の大地（チャイコフスキー、山本直純）'],
      ['我らの地球（ブラームス、山本直純）'],
      ['海上の道（團伊玖磨）'],
      ['「風の子守歌」（池辺晋一郎）より'],
      ['「イタリア歌曲集」より'],
      ['Disney Songs'],
    ],
    conductors: ['山口大五郎', '徳山裕介'],
  },
  {
    number: '37',
    date: '1997.12.13',
    place: 'きゅりあん大ホール',
    setlist: [
      ['CREDO（ビバルディ）'],
      ['万象（山本純ノ介）'],
      ['愛唱曲集より'],
      ['West Side Story'],
    ],
    conductors: ['山口大五郎', '祖谷孝克'],
  },
  {
    number: '36',
    date: '1996.12.21',
    place: '中央区立中央会館',
    setlist: [
      ['ミサ曲ロ短調より Sanctus（バッハ）'],
      ['メンデルスゾーン～愛を語る～'],
      ['蔵王（佐藤眞）'],
      ['丹沢山、はくちょう'],
      ["'We Wish You A Merry Christmas!'"],
    ],
    conductors: ['山口大五郎', '大前浩司', '杉原幸一'],
  },
  {
    number: '35',
    date: '1995.12.16',
    place: 'きゅりあん大ホール',
    setlist: [
      ['Te Deum（モーツァルト）'],
      ['Magnificat（パッヘルベル）'],
      ['伊勢志摩（小林秀雄）'],
      ['日本のうた、イギリスのうた（源田俊一郎）'],
      ['宇宙戦艦ヤマト（阿久悠）'],
    ],
    conductors: ['山口大五郎', '魚谷隆英'],
  },
  {
    number: '34',
    date: '1994.12.17',
    place: '中央区立中央会館',
    setlist: [
      ['Te Deum（モーツァルト）'],
      ['旅の途の風に（佐藤敏直）'],
      ['愛唱曲集より'],
      ['The Sound of Music（ロジャース）'],
    ],
    conductors: ['山口大五郎', '倉林進'],
  },
  {
    number: '33',
    date: '1993.12.18',
    place: '中央区立中央会館',
    setlist: [
      ['Ave Maria'],
      ['方舟（木下牧子）'],
      ['ふるさとの四季（源田俊一郎）'],
      ['水のいのち（高田三郎）'],
    ],
    conductors: ['山口大五郎', '下堂菌和宏'],
  },
  {
    number: '32',
    date: '1992.12.19',
    place: '中央区立中央会館',
    setlist: [
      ['C dur Messe（ベートーベン）'],
      ['花に寄せて（新実徳英）'],
      ['丹沢山、はくちょう'],
      ['West Side Story'],
    ],
    conductors: ['山口大五郎', '小樽茂稔'],
  },
  {
    number: '31',
    date: '1991.12.21',
    place: 'きゅりあん大ホール',
    setlist: [
      ['Messa di Gloria（プッチーニ）'],
      ['海上の道（團伊玖磨）'],
      ['ロシア民謡'],
      ['鼻長き僧の話（清水脩）'],
    ],
    conductors: ['山口大五郎', '出村雅彦'],
  },
  {
    number: '30',
    date: '1990.12.22',
    place: '一ツ橋ホール',
    setlist: [
      ['Stabat Mater（ベルディ）'],
      ['季節へのまなざし（荻久保和明）'],
      ['筑後川（團伊玖磨）'],
      ['Disney Songs'],
    ],
    conductors: ['山口大五郎', '山田真一'],
  },
  {
    number: '29',
    date: '1989.12.16',
    place: 'こまばエミナース',
    setlist: [
      ['レクイエム（フォーレ）'],
      ['日本叙情歌曲集（林光）'],
      ['Mentre il Cuculo、海はなかった 他'],
      ['宇宙戦艦ヤマト（阿久悠）'],
    ],
    conductors: ['山口大五郎', '田村輝之'],
  },
  {
    number: '28',
    date: '1988.12.16',
    place: 'こまばエミナース',
    setlist: [
      ['沙羅（信時潔、木下保）'],
      ['旅の途の風に（佐藤敏直）'],
      ['はくちょう、丹沢山'],
      ['オペラ合唱曲集 ハバネラ・闘牛士の歌 他'],
    ],
    conductors: ['山口大五郎', '永岡裕'],
  },
  {
    number: '27',
    date: '1987.12.11',
    place: 'こまばエミナース',
    setlist: [
      ['Jesu. meine Freude（バッハ）'],
      ['方舟（木下牧子）'],
      ['The Sound of Musicより'],
    ],
    conductors: ['山口大五郎', '鶴岡義久'],
  },
  {
    number: '26',
    date: '1986.12.16',
    place: 'こまばエミナース',
    setlist: [
      ['Magnificat（パッヘルベル）'],
      ['牧人の合唱（シューベルト）'],
      ['流浪の民（シューマン）'],
      ['花に寄せて（新実徳英)'],
      ['「風の航跡」（池辺晋一郎）より'],
      ['愛唱曲集より'],
      ['動物のカーニバル（サンサーンス、寺島尚彦）'],
    ],
    conductors: ['山口大五郎', '加藤佳幸'],
  },
  {
    number: '25',
    date: '1985.12.13',
    place: 'こまばエミナース',
    setlist: [
      ['- 丹沢山'],
      ['- 落葉松'],
      ['- 初恋'],
      ['- 天使'],
      ['- 鳥が'],
      ['- やさしい魚'],
      ['- はくちょう'],
      ['West Side Story'],
      ['銀河鉄道999'],
    ],
    conductors: ['山口大五郎', '富田篤尚'],
  },
  {
    number: '24',
    date: '1984.12.21',
    place: 'こまばエミナース',
    setlist: [
      ['グローリア（ビバルディ）'],
      ['風のうた（大中恩）'],
      ['はくちょう（大中恩） 初演'],
      ['クリスマス・キャロル'],
      ['ドイツ民謡集'],
      ['水のいのち（高田三郎）'],
    ],
    conductors: ['山口大五郎', '吉田貴文'],
  },
  {
    number: '23',
    date: '1983.12.15',
    place: '中央区立中央会館',
    setlist: [
      ['戴冠ミサ（モーツァルト）'],
      ['風紋（石井歓）'],
      ['スクリーンミュージック集 他'],
    ],
    conductors: ['山口大五郎', '種村隆'],
  },
  {
    number: '22',
    date: '1982.12.17',
    place: '朝日生命ホール',
    setlist: [
      ['丹沢山（中村太郎）'],
      ['月光とピエロ（清水脩）'],
      ['岬の墓（團伊玖磨）'],
      ['芸術家の生活（ヨハン・シュトラウス）'],
      ['女声合唱 踊れよ子ども（サンサーンス）、よろこびの歌（マセネイ）'],
      ['男声合唱 Funf Liederより（ブラームス）'],
      ['ジプシー生活（リスト）'],
    ],
    conductors: ['山口大五郎', '福泉武史'],
  },
  {
    number: '21',
    date: '1981.12.17',
    place: '朝日生命ホール',
    setlist: [
      ['Ave Verum 他（Byrd）'],
      ['三つの民謡 他（メンデルスゾーン）'],
      ['島よ（大中恩）'],
      ['銀河鉄道999'],
    ],
    conductors: ['山口大五郎', '長崎宏明'],
  },
  {
    number: '20',
    date: '1980',
    place: '',
    setlist: [
      ['C dur Messe（ベートーベン）'],
      ['太海にて（多田武彦）'],
      ['愛唱曲集より'],
    ],
    conductors: ['山口大五郎'],
  },
  {
    number: '19',
    date: '1979.12.14',
    place: '',
    setlist: [
      ['岬の墓（團伊玖磨）'],
      ['四季（田中利光）'],
      ['光る砂漠（荻原英彦）'],
      ['グローリア（モーツァルト）'],
      ['マニフィカート（パッヘルベル）'],
      ['わが家族の肖像'],
    ],
    conductors: ['山口大五郎'],
  },
  {
    number: '18',
    date: '1978.12.15',
    place: '朝日生命ホール',
    setlist: [
      ['レクイエム（フォーレ）'],
      ['遥かなものを（大中恩）'],
      ['マドリガル'],
      ['メンデルスゾーン作品集より'],
      ['ロザムンデより「狩人の合唱」「牧場の合唱」'],
    ],
    conductors: ['山口大五郎', '柏屋滋'],
  },
  {
    number: '17',
    date: '1977.12.16',
    place: '朝日生命ホール',
    setlist: [
      ['戴冠ミサ（モーツァルト）'],
      ['筑後川（團伊玖磨）'],
      ['ジプシー生活（リスト、レイボールド）'],
      ['Student Prince〈ロンベルク〉'],
    ],
    conductors: ['大久保真', '頃安雅樹'],
  },
  {
    number: '16',
    date: '1976.12.17',
    place: '朝日生命ホール',
    setlist: [['四季（田中利光）'], ['マニフィカート（バッハ）'], ['日本の歌']],
    conductors: ['大久保真', '山田照雄'],
  },
  {
    number: '15',
    date: '1975.12.19',
    place: '朝日生命ホール',
    setlist: [
      ['世界の民謡'],
      ['月光とピエロ（清水脩）'],
      ['田園ミサ（デイアベリ）'],
    ],
    conductors: ['大久保真', '山田照雄', '門倉真二'],
  },
  {
    number: '14',
    date: '1974.12.13',
    place: '大手町ホール',
    setlist: [
      ['マドリガル（フォーレ）'],
      ['水のいのち（高田三郎）'],
      ['レクイエム（ケルビーニ）'],
    ],
    conductors: ['大久保真', '後藤恵一'],
  },
  {
    number: '13',
    date: '1973.12.6',
    place: '新宿厚生年金会館',
    setlist: [
      ['筑後川（團伊玖磨）'],
      ['わたしの動物園（大中恩）'],
      ['わたしの願い（高田三郎）'],
      ['虹（磯部俶）'],
      ['戴冠ミサ（モーツァルト）'],
    ],
    conductors: ['大久保真', '上田卓'],
  },
  {
    number: '12',
    date: '1972.12.14',
    place: '朝日生命ホール',
    setlist: [
      ['ロザムンデ（シューベルト）'],
      ['五つの子どもの歌（大中恩）'],
      ['The Sound of Music'],
    ],
    conductors: ['中島良丈', '草薙太郎'],
  },
  {
    number: '11',
    date: '1971.12.16',
    place: 'イイノホール',
    setlist: [
      ['魚の譜（田中利光）'],
      ['マドリガル（フォーレ）'],
      ['旅（佐藤眞）'],
      ['レクイエム（フォーレ）'],
    ],
    conductors: ['中島良丈', '草薙太郎'],
  },
  {
    number: '10',
    date: '1970.12.11',
    place: '朝日ホール',
    setlist: [
      ['黒人霊歌集'],
      ['遥かなものを（大中恩）'],
      ['ウィンナ・ワルツ・ポルカ集（ヨハン・シュトラウス）'],
    ],
    conductors: ['中島良丈'],
  },
  {
    number: '9',
    date: '1969.12.11',
    place: 'イイノホール',
    setlist: [
      ['わたしの願い（高田三郎）'],
      ['カルミナ・ブラーナ（オルフ）'],
      ['混声合唱のための五つの歌（大中恩）'],
    ],
    conductors: ['中島良丈'],
  },
  {
    number: '8',
    date: '1968.12.9',
    place: '野村ホール',
    setlist: [
      ['自由の歌'],
      ['ドイツ民謡（ブラームス）'],
      ['おかあさんのばか（中田喜直・磯部俶）'],
      ['蔵王（佐藤眞）'],
    ],
    conductors: ['長井則文'],
  },
  {
    number: '7',
    date: '1967',
    place: '',
    setlist: [
      ['邦人曲集'],
      ['グローリア（モーツァルト）'],
      ['五月の歌（メンデルスゾーン）'],
    ],
    conductors: ['長井則文'],
  },
  {
    number: '6',
    date: '1966',
    place: '',
    setlist: [
      ['古典合唱曲（天地創造（ハイドン） 他）'],
      ['風と花粉、五つの子どもの歌（大中恩）'],
      ['黒人霊歌、イギリス民謡'],
      ['若い合唱（佐藤眞）'],
    ],
    conductors: ['長井則文'],
  },
  {
    number: '5',
    date: '1965.12.16',
    place: 'イイノホール',
    setlist: [
      ['日本民謡'],
      ['わたしの願い（高田三郎）'],
      ['黒人霊歌'],
      ['グローリア（ヴィヴァルディ）'],
    ],
    conductors: ['長井則文'],
  },
  {
    number: '4',
    date: '1964.12.19',
    place: '',
    setlist: [
      ['６つの屋外組曲（メンデルスゾーン）'],
      ['わたしの願い（高田三郎）'],
      ['Student Prince（ロンベルク）'],
      ['丹沢山（中村太郎）初演'],
      ['ベートーベン第９より「歓喜の歌」'],
    ],
    conductors: ['相原末治'],
  },
  {
    number: '3',
    date: '1963.12.14',
    place: '第一生命ホール',
    setlist: [['ジプシーと生活'], ['墓の妖精たち 他']],
    conductors: ['肥後一郎'],
  },
  {
    number: '2',
    date: '1962',
    place: '',
    setlist: [],
    conductors: [],
  },
  {
    number: '1',
    date: '1961',
    place: '',
    setlist: [['ドイツ民謡・ロシア民謡'], ['クリスマスキャロル']],
    conductors: ['藤井凡太'],
  },
];
